import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Snackbar,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import SessionContext from 'src/libs/SessionContext';
import { adminGetUserInfo, adminUpdateUserInfo } from 'src/libs/AdminServices';

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [initvalues, setInitValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  const getUserInfo = async () => {
    try {
      const res = await adminGetUserInfo();
      return res;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  useEffect(() => {
    async function fetchData() {
      const res = await getUserInfo();
      if (res && res.status === 200) {
        if (res.userinfo) {
          setInitValues({
            firstName: res.userinfo.firstname,
            lastName: res.userinfo.lastname,
            email: res.userinfo.email,
            phone: res.userinfo.phoneno
          });
        }
      } else if (res.status === 401) {
        alert('Sorry, your session has expired. Please log in again.');
        setUserSession({ ...userSession, isAuthenticated: false });
        sessionStorage.clear();
      }
    }
    fetchData();
  }, []);

  const UpdateUserInfo = async (values) => {
    try {
      const UserDto = {
        userid: userSession.UserID,
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        phoneno: values.phone
      };

      const res = await adminUpdateUserInfo(UserDto);

      if (res.status === 200) {
        setUserSession({ ...userSession, UserName: [values.firstName, values.lastName].join(' ') });

        setOpenSaveDone(true);
      } else if (res.status === 401) {
        alert('Sorry, your session has expired. Please log in again.');
        setUserSession({ ...userSession, isAuthenticated: false });
        sessionStorage.clear();
      } else {
        alert(res.error);
      }
    } catch (e) {
      alert('error');
    }
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initvalues}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          firstName: Yup.string().max(50).required('First name is required'),
          lastName: Yup.string().max(50).required('Last name is required')
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await UpdateUserInfo(values);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader="The information can be edited"
                title="Profile"
                titleTypographyProps={{ variant: 'h2' }}
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="First name"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label="Last name"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label="Phone Number"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <div className={classes.wrapper}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? 'Please wait ...' : 'Save details'}
                  </Button>
                  {isSubmitting
                    && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={openSaveDone}
        onClose={handleSaveDoneClose}
        message="Profile saved successfully"
        key="topcenter"
      />
    </div>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;

import React, { useState, forwardRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  IconButton,
  Chip,
  makeStyles
} from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from "material-table";
import getInitials from 'src/utils/getInitials';
import SessionContext from 'src/libs/SessionContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  label: {
    color: '#2F5597',
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Results = ({ className, subscriptions, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userSession, setUserSession } = React.useContext(SessionContext);

  const admincolumns = [
    {
      title: 'Subscription ID',
      field: 'subscriptionid'
    },
    {
      title: 'Customer',
      field: 'customername'
    },
    {
      title: 'Product',
      field: 'productname'
    },
    {
      title: 'Subscription Date',
      field: 'subscriptiondatedisplay'
    },
    {
      title: 'From',
      field: 'periodfromdisplay'
    },
    {
      title: 'To',
      field: 'periodtodisplay'
    },
    {
      title: 'No. of license',
      field: 'nooflicense'
    },
    {
      title: 'Status',
      field: 'statusname',
      render: (rowData) => {
        return (
          <Chip
            color="primary"
            label={rowData.statusname}
            size="small"
          />
        );
      }
    },
  ];

  const columns = [
    {
      title: 'ID',
      field: 'subscriptionid'
    },
    {
      title: 'Product',
      field: 'productname'
    },
    {
      title: 'Subscription Date',
      field: 'subscriptiondatedisplay'
    },
    {
      title: 'From',
      field: 'periodfromdisplay'
    },
    {
      title: 'To',
      field: 'periodtodisplay'
    },
    {
      title: 'No. of license',
      field: 'nooflicense'
    },
    {
      title: 'Status',
      field: 'statusname',
      render: (rowData) => {
        return (
          <Chip
            color="primary"
            label={rowData.statusname}
            size="small"
          />
        );
      }
    },
  ];

  const options = {
    exportButton: true,
    sorting: true
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        className={classes.cardtitle}
        title="Subscriptions"
        titleTypographyProps={{ variant: 'h3' }}
      />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <MaterialTable
              icons={tableIcons}
              title=""
              data={subscriptions}
              columns={userSession.UserType === 'Admin' ? admincolumns : columns}
              options={options}
              actions={[
                {
                  icon: tableIcons.Search,
                  tooltip: 'Details',
                  onClick: (event, rowData) => navigate(`/app/subscriptions/details?id=${rowData.subscriptionid}`, { replace: true })
                }
              ]}
            />
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  subscriptions: PropTypes.array.isRequired
};

export default Results;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Snackbar,
  Switch,
  FormControlLabel,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import {
  adminGetMarket,
  adminGetTimeZone,
  adminGetCustomerInfo,
  adminUpdateCustomer
} from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const CustomerDetailsView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const [loadingCustDetail, setLoadingCustDetail] = React.useState(true);
  const [markets, setMarkets] = React.useState([]);
  const [timezones, setTimezones] = React.useState([]);
  const [mode, setMode] = React.useState('Add');
  const navigate = useNavigate();
  const bIsAdmin = (userSession.UserType === 'Admin');
  const [initvalues, setInitValues] = useState({
    id: -1,
    email: '',
    companyName: '',
    contactperson: '',
    phone: '',
    benabled: false,
    market: {},
    timezone: {}
  });

  useEffect(() => {
    if (initvalues.id === -1) {
      (async () => {
        const value = queryString.parse(search);
        const custid = value.id ? value.id : userSession.CustomerID;

        let resMarkets = [];
        let selectedMarket = {};
        let resTimeZones = [];
        let selectedTimeZone = {};

        if (markets.length === 0) {
          resMarkets = await adminGetMarket();
          setMarkets(resMarkets);
        }

        if (timezones.length === 0) {
          resTimeZones = await adminGetTimeZone();
          setTimezones(resTimeZones);
        }

        if (!value.mode || value.mode !== 'add') {
          setMode('Edit');
          const res = await adminGetCustomerInfo(custid);
          if (res && res.status === 200 && res.customers && res.customers.length === 1) {
            const custinfo = res.customers[0];

            if (resMarkets && custinfo.market && resMarkets.length > 0) {
              resMarkets.forEach((market, index) => {
                if (market.marketCode === custinfo.market) {
                  selectedMarket = market;
                }
              });
            }

            if (resTimeZones && custinfo.timezoneid && resTimeZones.length > 0) {
              resTimeZones.forEach((timezone, index) => {
                if (timezone.timeZoneId === custinfo.timezoneid) {
                  selectedTimeZone = timezone;
                }
              });
            }

            setInitValues({
              id: custid,
              email: custinfo.email,
              companyName: custinfo.name,
              contactperson: custinfo.contactperson,
              phone: custinfo.phone,
              benabled: custinfo.benabled,
              market: selectedMarket,
              timezone: selectedTimeZone,
            });
          } else if (res.status === 401) {
            alert('Sorry, your session has expired. Please log in again.');
            setUserSession({ ...userSession, isAuthenticated: false });
            sessionStorage.clear();
          }
        }
        setLoadingCustDetail(false);
      })();
    }
  }, [initvalues]);

  const UpdateCustomer = async (values) => {
    try {
      const CustomerDto = {
        id: values.id,
        name: values.companyName,
        email: values.email,
        contactperson: values.contactperson,
        phone: values.phone,
        benabled: values.benabled,
        market: values.market.marketCode,
        timezoneid: values.timezone.timeZoneId,
      };

      const res = await adminUpdateCustomer(CustomerDto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  return (
    !loadingCustDetail && (
      <Page
        className={classes.root}
        title="Customer"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={initvalues}
              validationSchema={
                Yup.object().shape({
                  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  contactperson: Yup.string().max(50),
                  phone: Yup.string().max(50),
                  companyName: Yup.string().max(255).required('Company name is required')
                })
              }
              onSubmit={async (values, {
                setSubmitting,
                setFieldError
              }) => {
                if (!values.market || !values.market.marketCode) {
                  setFieldError('market', 'Market is required');
                } else if (!values.timezone || !values.timezone.timeZoneId) {
                  setFieldError('timezone', 'Time Zone is required');
                } else {
                  const res = await UpdateCustomer(values);

                  if (res) {
                    if (res.status === 200) {
                      setOpenSaveDone(true);
                      if (mode === 'Add') {
                        navigate('/app/customers', { replace: true });
                      }
                    } else if (res.status === 401) {
                      alert('Sorry, your session has expired. Please log in again.');
                      setUserSession({ ...userSession, isAuthenticated: false });
                      sessionStorage.clear();
                    } else if (res.errortype === 'name') {
                      setFieldError('companyName', res.error);
                    } else {
                      setFieldError('email', res.error);
                    }
                  }
                }
                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader
                      className={classes.cardtitle}
                      title={mode === 'Add' ? 'Add Customer' : 'Edit Customer'}
                      titleTypographyProps={{ variant: 'h2' }}
                    />
                    <Divider />
                    <CardContent>
                      <TextField
                        error={Boolean(touched.companyName && errors.companyName)}
                        fullWidth
                        helperText={touched.companyName && errors.companyName}
                        label="Company name"
                        margin="normal"
                        name="companyName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.companyName}
                        variant="outlined"
                      />
                      <Autocomplete
                        id="market"
                        name="market"
                        options={markets}
                        getOptionLabel={(option) => option.marketName}
                        value={values.market}
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          setFieldValue("market", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="market"
                            label="Market"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            value={values.market.marketName}
                            error={Boolean(touched.market && errors.market)}
                            helperText={touched.market && errors.market}
                          />
                        )}
                      />
                      <Autocomplete
                        id="timezone"
                        name="timezone"
                        options={timezones}
                        getOptionLabel={(option) => option.timeZoneId}
                        value={values.timezone}
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          setFieldValue("timezone", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="timezone"
                            label="Time Zone"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            value={values.timezone.timeZoneId}
                            error={Boolean(touched.timezone && errors.timezone)}
                            helperText={touched.timezone && errors.timezone}
                          />
                        )}
                      />
                      <TextField
                        error={Boolean(touched.contactperson && errors.contactperson)}
                        fullWidth
                        helperText={touched.contactperson && errors.contactperson}
                        label="Contact Person"
                        margin="normal"
                        name="contactperson"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.contactperson}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        label="Phone"
                        margin="normal"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      {bIsAdmin && (
                      <div align="right">
                        <FormControlLabel
                          control={(
                            <Switch
                              checked={values.benabled}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              color="primary"
                              name="benabled"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          )}
                          label="Enable"
                        />
                      </div>
                      )}
                      <Box my={2}>
                        <div className={classes.wrapper}>
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? 'Please wait ...' : 'Save'}
                          </Button>
                          {isSubmitting
                            && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </Box>
                    </CardContent>
                  </Card>
                </form>
              )}
            </Formik>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={3000}
              open={openSaveDone}
              onClose={handleSaveDoneClose}
              message="Profile updated successfully"
              key="topcenter"
            />
          </Container>
        </Box>
      </Page>
    )
  );
};

export default CustomerDetailsView;

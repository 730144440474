import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  ShoppingCart as ShoppingCartIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  LogOut as LogoutIcon,
  Archive as ArchiveIcon,
  FileText as FileTextIcon
} from 'react-feather';
import { adminLogout } from 'src/libs/AdminServices';
import SessionContext from 'src/libs/SessionContext';
import getInitials from 'src/utils/getInitials';
import NavItem from './NavItem';

const adminitems = [
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/app/cabinet',
    icon: ArchiveIcon,
    title: 'Customer Cabinet'
  },
  {
    href: '/app/billingrequest',
    icon: FileTextIcon,
    title: 'Billing Request'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Solutions'
  },
  {
    href: '/app/subscriptions',
    icon: ShoppingCartIcon,
    title: 'Subscriptions'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }
];

const generalitems = [
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Solutions'
  },
  {
    href: '/app/subscriptions',
    icon: ShoppingCartIcon,
    title: 'Subscriptions'
  },
  {
    href: '/app/customers/details',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [user, setUserInfo] = useState({ avatar: '', jobTitle: '', name: '' });
  const items = (userSession.UserType === 'Admin' ? adminitems : generalitems);
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    setUserInfo({
      avatar: userSession.Avatar,
      jobTitle: userSession.UserType === 'Admin' ? 'Administrator' : '',
      name: userSession.UserName
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      const _userSession = {
        isAuthenticated: false,
        isAuthenticating: false,
        showTimeOutLogin: false,
        UserID: '',
        UserName: '',
        UserType: '',
        UserEmail: '',
        Avatar: '',
        token: ''
      };

      const res = await adminLogout();
      setUserSession(_userSession);
      sessionStorage.clear();

      navigate('/login', { replace: true });
    } catch (e) {
      alert('error');
    }
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        >
          {getInitials(userSession.UserName)}
        </Avatar>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          {openMobile && (
            <NavItem
              href="#"
              onClick={handleLogout}
              key="Logout"
              title="Logout"
              icon={LogoutIcon}
            />
          )}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;

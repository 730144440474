import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import SessionContext from 'src/libs/SessionContext';
import LoginForm from 'src/views/auth/LoginForm';

const LoginDialogRaw = (props) => {
  const {
    onClose,
    open,
    ...other
  } = props;

  const handleLoginOK = () => {
    onClose();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography variant="h3">
          Sorry, your session has expired. Please sign in again.
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <LoginForm onLoginOK={handleLoginOK} />
      </DialogContent>
    </Dialog>
  );
};

LoginDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));

const LoginDialog = () => {
  const classes = useStyles();
  const { userSession, setUserSession } = React.useContext(SessionContext);

  const handleClose = () => {
    setUserSession({ ...userSession, showTimeOutLogin: false });
  };

  return (
    <div className={classes.root}>
      <LoginDialogRaw
        classes={{
          paper: classes.paper,
        }}
        id="login-menu"
        keepMounted
        open={userSession.showTimeOutLogin}
        onClose={handleClose}
      />
    </div>
  );
};

export default LoginDialog;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Snackbar,
  Switch,
  FormControlLabel,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import {
  adminGetCustTaskAction,
  adminUpdateCustTaskAction,
  adminGetUploadedFile
} from 'src/libs/AdminServices';
import { DropzoneAreaBase } from 'material-ui-dropzone';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const TaskUploadFileView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const [loadingTaskAction, setLoadingTaskAction] = React.useState(true);
  const [fileName, setFileName] = React.useState('');
  const [fileContent, setFileContent] = React.useState('');
  const [mode, setMode] = React.useState('Add');
  const navigate = useNavigate();
  const bIsAdmin = (userSession.UserType === 'Admin');
  const [initvalues, setInitValues] = useState({
    id: '',
    seqno: 0,
    UploadFileRemark: '',
  });

  useEffect(() => {
    if (initvalues.id === '') {
      (async () => {
        const value = queryString.parse(search);
        const [taskid, seqno] = [value.tid, value.seqno];
        setInitValues({ ...initvalues, id: taskid, seqno });

        if (!value.mode || value.mode !== 'add') {
          setMode('Edit');

          const uploadedFileDto = {
            taskid,
            seqno
          };

          const res = await adminGetUploadedFile(uploadedFileDto);

          if (res && res.status === 200 && res.custtaskfile) {
            const taskinfo = res.custtaskfile;

            setInitValues({
              id: taskid,
              seqno,
              UploadFileRemark: taskinfo.uploadfileremark,
            });
          } else if (res.status === 401) {
            alert('Sorry, your session has expired. Please log in again.');
            setUserSession({ ...userSession, isAuthenticated: false });
            sessionStorage.clear();
          }
        }
        setLoadingTaskAction(false);
      })();
    }
  }, [initvalues]);

  const handleAddFile = async (files) => {
    files.forEach((file) => {
      setFileContent(file.data);
      setFileName(file.file.name);
    });

    return false;
  };

  const handleSaveUpload = async (values) => {
    const formData = new FormData();

    if (mode !== 'Edit' && !fileContent) {
      alert('Please select the file to upload first');
      return false;
    }

    formData.append('filecontent', fileContent);
    formData.append('filename', fileName);
    formData.append('userid', userSession.UserID);
    formData.append('taskid', values.id);
    formData.append('seqno', values.seqno);
    formData.append('uploadfileremark', values.UploadFileRemark);

    try {
      const res = await axios.post('/api/admin/UploadCustTaskFile', formData);
      if (res.data.status === 200) {
        navigate('/app/cabinet', { replace: true });
      }
    } catch (ex) {
      console.log(ex);
    }

    return false;
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  return (
    !loadingTaskAction && (
      <Page
        className={classes.root}
        title="Customer Cabinet Upload File"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={initvalues}
              validationSchema={
                Yup.object().shape({
                  UploadFileRemark: Yup.string().max(500)
                })
              }
              onSubmit={async (values, {
                setSubmitting,
                setFieldError
              }) => {
                const res = await handleSaveUpload(values);

                if (res) {
                  if (res.status === 200) {
                    setOpenSaveDone(true);
                    navigate('/app/cabinet', { replace: true });
                  } else if (res.status === 401) {
                    alert('Sorry, your session has expired. Please log in again.');
                    setUserSession({ ...userSession, isAuthenticated: false });
                    sessionStorage.clear();
                  }
                }
                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader
                      className={classes.cardtitle}
                      title={mode === 'Add' ? 'Upload File' : 'Upload File'}
                      titleTypographyProps={{ variant: 'h2' }}
                    />
                    <Divider />
                    <CardContent>
                      <TextField
                        error={Boolean(touched.UploadFileRemark && errors.UploadFileRemark)}
                        fullWidth
                        helperText={touched.UploadFileRemark && errors.UploadFileRemark}
                        label="Remark"
                        margin="normal"
                        name="UploadFileRemark"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.UploadFileRemark}
                        multiline
                        rows={4}
                        maxRows={10}
                        variant="outlined"
                      />
                      {mode !== 'Edit' && (
                        <DropzoneAreaBase
                          onAdd={(fileObjs) => handleAddFile(fileObjs)}
                          onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                          onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                        />
                      )}
                      <Box my={4}>
                        <table border="0" cellPadding="2" cellSpacing="0" align="right">
                          <tr>
                            <td>
                              <div className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  onClick={() => navigate(`/app/cabinet`, { replace: true })}
                                >
                                  Back
                                </Button>
                              </div>
                            </td>
                            <td>
                              <div className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting}
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                >
                                  {isSubmitting ? 'Please wait ...' : 'Save'}
                                </Button>
                                {isSubmitting && (
                                  <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </Box>
                    </CardContent>
                  </Card>
                </form>
              )}
            </Formik>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={3000}
              open={openSaveDone}
              onClose={handleSaveDoneClose}
              message="Customer cabinet updated successfully"
              key="topcenter"
            />
          </Container>
        </Box>
      </Page>
    )
  );
};

export default TaskUploadFileView;

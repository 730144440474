import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Grid,
  Container,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  MenuItem,
  Snackbar,
  FormControlLabel,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import {
  adminGetBillingRequestInfo,
  adminUpdateBillingRequest,
  adminGetTrackingCustomerInfo
} from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const RequestDetailsView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [inited, setInited] = React.useState(false);
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const [openCustomer, setOpenCustomer] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState({});
  const loading = openCustomer && customers.length === 0;
  const [openFundedCustomer, setOpenFundedCustomer] = React.useState(false);
  const [fundedcustomers, setFundedCustomers] = React.useState([]);
  const [fundedcustomer, setFundedCustomer] = React.useState({});
  const loadingFundedCust = openFundedCustomer && fundedcustomers.length === 0;
  const [loadingBillingRequestDetail, setLoadingBillingRequestDetail] = React.useState(true);
  const [mode, setMode] = React.useState('Add');
  const navigate = useNavigate();
  const bIsAdmin = (userSession.UserType === 'Admin');
  const [initvalues, setInitValues] = useState({
    requestid: '',
    requestdate: new Date(),
    requeststatus: 'R',
    billingdate: new Date(),
    requestby: '',
    company: 'XML',
    quotno: '',
    custname: '',
    trackcustno: '',
    fundedcustname: '',
    fundedtrackcustno: '',
    invoiceattention: '',
    projectdescription: '',
    customerreference: '',
    invoicedescription: '',
    invoicecurrency: 'USD',
    invoiceamount: 0,
    paymentmethod: 'One',
    otherpaymentmethoddesc: '',
    sabrenoofmandays: 0,
    sabretrackingref: '',
    sabrepono: '',
    sabrecustfunded: 'N',
    sabreremark: '',
    custmarket: '',
    fundedcustmarket: '',
    bprocessingfee: false,
    processingfeevalue: 2.8
  });

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await adminGetTrackingCustomerInfo();
      if (active) {
        setCustomers(res.customers);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    let active = true;

    if (!loadingFundedCust) {
      return undefined;
    }

    (async () => {
      const res = await adminGetTrackingCustomerInfo();
      if (active) {
        setFundedCustomers(res.customers);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingFundedCust]);

  useEffect(() => {
    if (!inited) {
      let curdate = new Date().setHours(0, 0, 0, 0);
      curdate = new Date(curdate);

      (async () => {
        const value = queryString.parse(search);
        const requestid = value.rid;

        if (!value.mode || value.mode !== 'add') {
          if (value.mode === 'copy') {
            setMode('Add');
          } else {
            setMode('Edit');
          }

          const res = await adminGetBillingRequestInfo(requestid);
          if (res && res.status === 200 && res.billingreqs && res.billingreqs.length === 1) {
            const reqinfo = res.billingreqs[0];

            setInitValues({
              ...initvalues,
              requestid,
              requestdate: reqinfo.requestdate,
              requeststatus: reqinfo.requeststatus,
              requestby: reqinfo.requestby,
              billingdate: reqinfo.billingdate,
              company: reqinfo.company,
              quotno: reqinfo.quotno,
              custname: reqinfo.custname,
              trackcustno: reqinfo.trackcustno,
              fundedcustname: reqinfo.fundedcustname,
              fundedtrackcustno: reqinfo.fundedtrackcustno,
              invoiceattention: reqinfo.invoiceattention,
              projectdescription: reqinfo.projectdescription,
              customerreference: reqinfo.customerreference,
              invoicedescription: reqinfo.invoicedescription,
              invoicecurrency: reqinfo.invoicecurrency,
              invoiceamount: reqinfo.invoiceamount,
              paymentmethod: reqinfo.paymentmethod,
              otherpaymentmethoddesc: reqinfo.otherpaymentmethoddesc,
              sabrenoofmandays: reqinfo.sabrenoofmandays,
              sabretrackingref: reqinfo.sabretrackingref,
              sabrepono: reqinfo.sabrepono,
              sabrecustfunded: reqinfo.sabrecustfunded,
              sabreremark: reqinfo.sabreremark,
              custmarket: reqinfo.custmarket,
              fundedcustmarket: reqinfo.fundedcustmarket,
              bprocessingfee: reqinfo.bprocessingfee,
              processingfeevalue: reqinfo.processingfeevalue
            });

            setCustomer({
              id: reqinfo.trackcustno,
              name: reqinfo.custname,
              countryname: reqinfo.custmarket
            });

            setFundedCustomer({
              id: reqinfo.fundedtrackcustno,
              name: reqinfo.fundedcustname,
              countryname: reqinfo.fundedcustmarket
            });

            if (value.mode === 'copy') {
              setInitValues({
                ...initvalues,
                requestid: '',
                requestdate: curdate,
                requeststatus: 'R'
              });
            }
          } else if (res.status === 401) {
            alert('Sorry, your session has expired. Please log in again.');
            setUserSession({ ...userSession, isAuthenticated: false });
            sessionStorage.clear();
          }
        } else {
          setInitValues({
            ...initvalues,
            requestdate: curdate,
            billingdate: new Date(),
            invoiceamount: 0,
            sabrenoofmandays: 0,
          });
        }
        setLoadingBillingRequestDetail(false);
      })();

      setInited(true);
    }
  }, [initvalues]);

  React.useEffect(() => {
    if (!openCustomer) {
      setCustomers([]);
    }
  }, [openCustomer]);

  React.useEffect(() => {
    if (!openFundedCustomer) {
      setFundedCustomers([]);
    }
  }, [openFundedCustomer]);

  const UpdateBillingRequest = async (values) => {
    try {
      const BillingReqDto = {
        requestid: values.requestid,
        requestdate: values.requestdate,
        requestdatestring: moment(values.requestdate).format('YYYY/MM/DD'),
        requestby: values.requestby,
        requeststatus: values.requeststatus,
        billingdate: values.billingdate,
        billingdatestring: moment(values.billingdate).format('YYYY/MM/DD'),
        company: values.company,
        quotno: values.quotno,
        custname: customer ? customer.name : '',
        trackcustno: values.trackcustno ? values.trackcustno.toString() : '',
        fundedcustname: fundedcustomer ? fundedcustomer.name : '',
        fundedtrackcustno: values.fundedtrackcustno ? values.fundedtrackcustno.toString() : '',
        invoiceattention: values.invoiceattention,
        projectdescription: values.projectdescription,
        customerreference: values.customerreference,
        invoicedescription: values.invoicedescription,
        invoicecurrency: values.invoicecurrency,
        invoiceamount: values.invoiceamount,
        paymentmethod: values.paymentmethod,
        otherpaymentmethoddesc: values.otherpaymentmethoddesc,
        sabrenoofmandays: values.sabrenoofmandays,
        sabretrackingref: values.sabretrackingref,
        sabrepono: values.sabrepono,
        sabrecustfunded: values.sabrecustfunded,
        sabreremark: values.sabreremark,
        custmarket: customer ? customer.countryname : '',
        fundedcustmarket: fundedcustomer ? fundedcustomer.countryname : '',
        bprocessingfee: values.bprocessingfee,
        processingfeevalue: values.processingfeevalue
      };

      const res = await adminUpdateBillingRequest(BillingReqDto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  const handleRequestDateChange = (values, date) => {
    setInitValues({
      ...values,
      requestdate: date
    });
  };

  const handleBillingDateChange = (values, date) => {
    setInitValues({
      ...values,
      billingdate: date
    });
  };

  const handleProcessingFeeChange = (values, event) => {
    event.preventDefault();
    setInitValues({ ...values, bprocessingfee: event.target.checked });
  };

  return (
    !loadingBillingRequestDetail && (
      <Page
        className={classes.root}
        title="Invoice Billing Request"
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <Container>
            <Formik
              enableReinitialize
              initialValues={initvalues}
              validationSchema={
                Yup.object().shape({
                  requestby: Yup.string().max(50),
                  quotno: Yup.string().max(50)
                })
              }
              onSubmit={async (values, {
                setSubmitting,
                setFieldError
              }) => {
                const res = await UpdateBillingRequest(values);

                if (res) {
                  if (res.status === 200) {
                    setOpenSaveDone(true);
                    if (mode === 'Add') {
                      navigate('/app/billingrequest', { replace: true });
                    }
                  } else if (res.status === 401) {
                    alert('Sorry, your session has expired. Please log in again.');
                    setUserSession({ ...userSession, isAuthenticated: false });
                    sessionStorage.clear();
                  }
                }

                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader
                      className={classes.cardtitle}
                      title={mode === 'Add' ? 'Add Invoice Billing Request' : 'Edit Invoice Billing Request'}
                      titleTypographyProps={{ variant: 'h2' }}
                    />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <TextField
                            error={Boolean(touched.requestby && errors.requestby)}
                            fullWidth
                            helperText={touched.requestby && errors.requestby}
                            label="Requested by"
                            margin="normal"
                            name="requestby"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.requestby}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              error={Boolean(touched.requestdate && errors.requestdate)}
                              helperText={touched.requestdate && errors.requestdate}
                              margin="normal"
                              id="requestdate"
                              name="requestdate"
                              label="Request Date"
                              format="DD MMM YYYY"
                              onChange={(e) => handleRequestDateChange(values, e)}
                              onBlur={handleBlur}
                              value={values.requestdate}
                              variant="outlined"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <TextField
                            error={Boolean(touched.requeststatus && errors.requeststatus)}
                            fullWidth
                            helperText={touched.requeststatus && errors.requeststatus}
                            label="Status"
                            margin="normal"
                            name="requeststatus"
                            select
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.requeststatus}
                            variant="outlined"
                          >
                            <MenuItem value="R">Raise</MenuItem>
                            <MenuItem value="C">Confirm</MenuItem>
                            <MenuItem value="I">Invoiced</MenuItem>
                            <MenuItem value="V">Void</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.company && errors.company)}
                            fullWidth
                            helperText={touched.company && errors.company}
                            label="Company"
                            margin="normal"
                            name="company"
                            select
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.company}
                            variant="outlined"
                          >
                            <MenuItem value="XML">XML</MenuItem>
                            <MenuItem value="DE">Dynamic eService</MenuItem>
                            <MenuItem value="ABS">ABS</MenuItem>
                            <MenuItem value="XMLSIN">XML Singapore</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <TextField
                            error={Boolean(touched.requestid && errors.requestid)}
                            fullWidth
                            helperText={touched.requestid && errors.requestid}
                            label="Request No."
                            margin="normal"
                            name="requestid"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.requestid}
                            variant="outlined"
                            inputProps={
                              { readOnly: true, }
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <TextField
                            error={Boolean(touched.quotno && errors.quotno)}
                            fullWidth
                            helperText={touched.quotno && errors.quotno}
                            label="Quotation No."
                            margin="normal"
                            name="quotno"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.quotno}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              error={Boolean(touched.billingdate && errors.billingdate)}
                              helperText={touched.billingdate && errors.billingdate}
                              margin="normal"
                              id="billingdate"
                              name="billingdate"
                              label="Billing Date"
                              format="DD MMM YYYY"
                              onChange={(e) => handleBillingDateChange(values, e)}
                              onBlur={handleBlur}
                              value={values.billingdate}
                              variant="outlined"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          item
                          md={7}
                          xs={12}
                        >
                          <Autocomplete
                            id="trackcustno"
                            name="trackcustno"
                            open={openCustomer}
                            onOpen={() => {
                              setOpenCustomer(true);
                            }}
                            onClose={() => {
                              setOpenCustomer(false);
                            }}
                            options={customers}
                            loading={loading}
                            getOptionLabel={(option) => option.name}
                            value={customer}
                            onBlur={handleBlur}
                            onChange={(e, value) => {
                              setFieldValue('customer', value);
                              setCustomer(value);
                              setInitValues({
                                ...values,
                                trackcustno: value ? value.id : '',
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="customer"
                                label="Customer (Bill To)"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                error={Boolean(touched.customer && errors.customer)}
                                helperText={touched.customer && errors.customer}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          md={5}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.invoiceattention && errors.invoiceattention)}
                            fullWidth
                            helperText={touched.invoiceattention && errors.invoiceattention}
                            label="Invoice Attention"
                            margin="normal"
                            name="invoiceattention"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.invoiceattention}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.projectdescription && errors.projectdescription)}
                            fullWidth
                            helperText={touched.projectdescription && errors.projectdescription}
                            label="Project Description"
                            margin="normal"
                            name="projectdescription"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.projectdescription}
                            variant="outlined"
                            multiline
                            rows={6}
                          />
                        </Grid>
                        <Grid
                          item
                          md={3}
                          xs={4}
                        >
                          <TextField
                            error={Boolean(touched.invoicecurrency && errors.invoicecurrency)}
                            fullWidth
                            helperText={touched.invoicecurrency && errors.invoicecurrency}
                            label="Invoice Currency"
                            margin="normal"
                            name="invoicecurrency"
                            select
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.invoicecurrency}
                            variant="outlined"
                          >
                            <MenuItem value="HKD">HKD</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="SGD">SGD</MenuItem>
                            <MenuItem value="AUD">AUD</MenuItem>
                            <MenuItem value="NZD">NZD</MenuItem>
                            <MenuItem value="RMB">RMB</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={8}
                        >
                          <TextField
                            error={Boolean(touched.invoiceamount && errors.invoiceamount)}
                            fullWidth
                            helperText={touched.invoiceamount && errors.invoiceamount}
                            label="Invoice Amount"
                            margin="normal"
                            name="invoiceamount"
                            type="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.invoiceamount}
                            variant="outlined"
                          />
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={values.bprocessingfee}
                                onChange={(e) => handleProcessingFeeChange(values, e)}
                              />
                            )}
                            label="+ Processing Fee"
                          />
                          {values.bprocessingfee && (
                            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                value={values.processingfeevalue}
                                onChange={handleChange('processingfeevalue')}
                                type="number"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'processingfeevalue',
                                }}
                              />
                            </FormControl>
                          )}
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.paymentmethod && errors.paymentmethod)}
                            fullWidth
                            helperText={touched.paymentmethod && errors.paymentmethod}
                            label="Payment"
                            margin="normal"
                            name="paymentmethod"
                            select
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.paymentmethod}
                            variant="outlined"
                          >
                            <MenuItem value="One">One-time</MenuItem>
                            <MenuItem value="Dep">Deposit</MenuItem>
                            <MenuItem value="First">First</MenuItem>
                            <MenuItem value="Second">Second</MenuItem>
                            <MenuItem value="Final">Final</MenuItem>
                            <MenuItem value="M">Monthly</MenuItem>
                            <MenuItem value="Q">Quarterly</MenuItem>
                            <MenuItem value="Y">Yearly</MenuItem>
                            <MenuItem value="Oth">Others</MenuItem>
                          </TextField>
                        </Grid>
                        {values.paymentmethod === "Oth" && (
                          <Grid
                            item
                            md={8}
                            xs={12}
                          >
                            <TextField
                              error={
                                Boolean(touched.otherpaymentmethoddesc
                                  && errors.otherpaymentmethoddesc)
                              }
                              fullWidth
                              helperText={
                                touched.otherpaymentmethoddesc
                                && errors.otherpaymentmethoddesc
                              }
                              label=""
                              margin="normal"
                              name="otherpaymentmethoddesc"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.otherpaymentmethoddesc}
                              variant="outlined"
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          md={12}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.customerreference && errors.customerreference)}
                            fullWidth
                            helperText={touched.customerreference && errors.customerreference}
                            label="Customer Reference (if any)"
                            margin="normal"
                            name="customerreference"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.customerreference}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.invoicedescription && errors.invoicedescription)}
                            fullWidth
                            helperText={touched.invoicedescription && errors.invoicedescription}
                            label="Invoice Description (special request)"
                            margin="normal"
                            name="invoicedescription"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.invoicedescription}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <br />
                  <Card>
                    <CardHeader
                      className={classes.cardtitle}
                      title="Sabre APAC Billing Only"
                      titleTypographyProps={{ variant: 'h3' }}
                    />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          md={4}
                          xs={8}
                        >
                          <TextField
                            error={Boolean(touched.sabrenoofmandays && errors.sabrenoofmandays)}
                            fullWidth
                            helperText={touched.sabrenoofmandays && errors.sabrenoofmandays}
                            label="No. of Man-Days"
                            margin="normal"
                            name="sabrenoofmandays"
                            type="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sabrenoofmandays}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <TextField
                            error={Boolean(touched.sabretrackingref && errors.sabretrackingref)}
                            fullWidth
                            helperText={touched.sabretrackingref && errors.sabretrackingref}
                            label="Tracking Ref."
                            margin="normal"
                            name="sabretrackingref"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sabretrackingref}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={6}
                        >
                          <TextField
                            error={Boolean(touched.sabrepono && errors.sabrepono)}
                            fullWidth
                            helperText={touched.sabrepono && errors.sabrepono}
                            label="PO No."
                            margin="normal"
                            name="sabrepono"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sabrepono}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={3}
                          xs={12}
                        >
                          <FormControl>
                            <FormLabel id="sabrecustfunded-label">Customer Funded</FormLabel>
                            <RadioGroup
                              row
                              name="sabrecustfunded"
                              value={values.sabrecustfunded}
                              onChange={handleChange}
                            >
                              <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                              <FormControlLabel value="N" control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {values.sabrecustfunded === "Y" && (
                          <Grid
                            item
                            md={9}
                            xs={12}
                          >
                            <Autocomplete
                              id="fundedtrackcustno"
                              name="fundedtrackcustno"
                              open={openFundedCustomer}
                              onOpen={() => {
                                setOpenFundedCustomer(true);
                              }}
                              onClose={() => {
                                setOpenFundedCustomer(false);
                              }}
                              options={fundedcustomers}
                              loading={loadingFundedCust}
                              getOptionLabel={(optionfunded) => optionfunded.name}
                              value={fundedcustomer}
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue('fundedcustomer', value);
                                setFundedCustomer(value);
                                setInitValues({
                                  ...values,
                                  fundedtrackcustno: value ? value.id : '',
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="fundedcustomer"
                                  label="Customer"
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  error={Boolean(touched.fundedcustomer && errors.fundedcustomer)}
                                  helperText={touched.fundedcustomer && errors.fundedcustomer}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loadingFundedCust ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          md={12}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.sabreremark && errors.sabreremark)}
                            fullWidth
                            helperText={touched.sabreremark && errors.sabreremark}
                            label="Remark"
                            margin="normal"
                            name="sabreremark"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sabreremark}
                            variant="outlined"
                          />
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                  <Box my={2}>
                    <table border="0" cellPadding="2" cellSpacing="0" align="right">
                      <tr>
                        <td>
                          <div className={classes.wrapper}>
                            <Button
                              color="primary"
                              size="large"
                              type="button"
                              variant="contained"
                              onClick={() => navigate(`/app/billingrequest`, { replace: true })}
                            >
                              Back
                            </Button>
                          </div>
                        </td>
                        <td>
                          <div className={classes.wrapper}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {isSubmitting ? 'Please wait ...' : 'Save'}
                            </Button>
                            {isSubmitting && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </Box>
                </form>
              )}
            </Formik>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={3000}
              open={openSaveDone}
              onClose={handleSaveDoneClose}
              message="Billing Request updated successfully"
              key="topcenter"
            />
          </Container>
        </Box>
      </Page>
    )
  );
};

export default RequestDetailsView;

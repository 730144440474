import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Box,
  Card,
  CardContent,
  Container,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import LoginForm from './LoginForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const navigate = useNavigate();

  const RedirectToHome = () => {
    const qstr = queryString.parse(search);
    if (qstr.page && qstr.page === 'pay' && qstr.sid && qstr.bid) {
      navigate(`/app/subscriptions/payform?sid=${qstr.sid}&bid=${qstr.bid}`, { replace: true });
    } else {
      navigate('/app/subscriptions', { replace: true });
    }
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Sign in
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  Sign in to XML Online Payment Platform
                </Typography>
              </Box>
              <LoginForm onLoginOK={RedirectToHome} />
              <Typography
                color="textSecondary"
                align="center"
                variant="body1"
              >
                <Link
                  component={RouterLink}
                  to="/forgotpassword"
                  variant="h6"
                >
                  Forgot password
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;

import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [bIncludeCompleted, setBIncludeCompleted] = React.useState(false);

  const handleShowCompleteChange = (event) => {
    setBIncludeCompleted(event.target.checked);
    if (event.target.checked) {
      navigate('/app/cabinet?includeDone=Y', { replace: true });
    } else {
      navigate('/app/cabinet?includeDone=N', { replace: true });
    }
    window.location.reload(false);
  };

  useEffect(() => {
    const value = queryString.parse(search);
    if (value.includeDone && value.includeDone === "Y") {
      setBIncludeCompleted(true);
    }
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <FormControlLabel
        control={
          <Checkbox checked={bIncludeCompleted} onChange={handleShowCompleteChange} />
        }
        label="Show Completed Records"
      />
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => { navigate(`/app/cabinet/details?mode=add`, { replace: true }); }}
        >
          Add
        </Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;

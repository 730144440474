import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import queryString from 'query-string';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Link,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormHelperText,
  Snackbar,
  IconButton,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from '@tinymce/tinymce-react';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import { adminGetSubscriptionDetailForEmail, adminSendEmail } from 'src/libs/AdminServices';
import SubscriptionInfo from 'src/views/subscription/SubscriptionDetailsView/SubscriptionInfo';
import BillingInfo from 'src/views/subscription/PayBillView/BillingInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const PayEmailView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [loadingDetail, setLoadingDetail] = React.useState(true);
  const [subscriptionDetail, setSubscriptionDetail] = React.useState({});
  const [initvalues, setInitvalues] = React.useState({});
  const [openEmailDone, setOpenEmailDone] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchData() {
      const value = queryString.parse(search);

      try {
        const res = await adminGetSubscriptionDetailForEmail(
          value.sid,
          value.bid
        );

        if (res && res.status === 200) {
          setSubscriptionDetail(res);
          setInitvalues({
            sender: res.emailinfo.sender,
            recipient: res.emailinfo.recipient,
            cc: res.emailinfo.cc,
            bcc: res.emailinfo.bcc,
            subject: res.emailinfo.subject,
            emailbody: res.emailinfo.emailbody,
          });
        } else if (res.status === 401) {
          alert('Sorry, your session has expired. Please log in again.');
          setUserSession({ ...userSession, isAuthenticated: false });
          sessionStorage.clear();
        } else {
          alert(res.error);
        }

        setLoadingDetail(false);
      } catch (e) {
        console.log(e);
        setLoadingDetail(false);
      }
    }
    fetchData();
  }, []);

  const SendEmail = async (values) => {
    try {
      const EmailDto = {
        sender: values.sender,
        recipient: values.recipient,
        cc: values.cc,
        bcc: values.bcc,
        subject: values.subject,
        emailbody: values.emailbody,
      };

      const res = await adminSendEmail(EmailDto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleEmailDoneClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenEmailDone(false);
  };

  return (
    !loadingDetail && subscriptionDetail.status === 200 && (
      <Page
        className={classes.root}
        title="Payment Email"
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <Container maxWidth="lg">
            <SubscriptionInfo
              customerinfo={subscriptionDetail.customerinfo}
              subscription={subscriptionDetail.subscription}
            />
            <br />
            <BillingInfo
              billing={subscriptionDetail.subscription.billings[0]}
            />
            <br />
            <Card>
              <CardHeader
                className={classes.cardtitle}
                title="Email Details"
                titleTypographyProps={{ variant: 'h3' }}
              />
              <Divider />
              <CardContent>
                <Formik
                  enableReinitialize
                  initialValues={initvalues}
                  validationSchema={
                    Yup.object().shape({
                      sender: Yup.string().max(100).required('From is required'),
                      recipient: Yup.string().max(200).required('To is required'),
                      cc: Yup.string().max(200),
                      bcc: Yup.string().max(200),
                      subject: Yup.string().max(100).required('Subject is required')
                    })
                  }
                  onSubmit={async (values, {
                    setSubmitting
                  }) => {
                    if (!values.emailbody) {
                      alert('Please input the email content');
                    } else {
                      const res = await SendEmail(values);

                      if (res) {
                        if (res.status === 200) {
                          setOpenEmailDone(true);
                        }
                      }
                    }
                    setSubmitting(false);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.sender && errors.sender)}
                        fullWidth
                        helperText={touched.sender && errors.sender}
                        label="From"
                        margin="normal"
                        name="sender"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sender}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.recipient && errors.recipient)}
                        fullWidth
                        helperText={touched.recipient && errors.recipient}
                        label="To"
                        margin="normal"
                        name="recipient"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.recipient}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.cc && errors.cc)}
                        fullWidth
                        helperText={touched.cc && errors.cc}
                        label="Cc"
                        margin="normal"
                        name="cc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cc}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.bcc && errors.bcc)}
                        fullWidth
                        helperText={touched.bcc && errors.bcc}
                        label="Bcc"
                        margin="normal"
                        name="bcc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bcc}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.subject && errors.subject)}
                        fullWidth
                        helperText={touched.subject && errors.subject}
                        label="Subject"
                        margin="normal"
                        name="subject"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.subject}
                        variant="outlined"
                      />
                      <Editor
                        id="emailbody"
                        error={Boolean(touched.emailbody && errors.emailbody)}
                        fullWidth
                        helperText={touched.emailbody && errors.emailbody}
                        label="Content"
                        initialValue={values.emailbody}
                        apiKey="43v8hwbux4mygn7um6mi623dv92s59m07on5gxqjqcwzlm1t"
                        init={{
                          height: 400,
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image',
                            'charmap print preview anchor help',
                            'searchreplace visualblocks code',
                            'insertdatetime media table paste wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic | \n'
                            + 'alignleft aligncenter alignright | \n'
                            + 'bullist numlist outdent indent | help'
                        }}
                        name="emailbody"
                        onBlur={handleBlur}
                        onEditorChange={(e) => {
                          handleChange({ target: { name: 'emailbody', value: e } });
                        }}
                        variant="outlined"
                      />
                      <Box my={2}>
                        <div className={classes.wrapper}>
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? 'Please wait ...' : 'Send'}
                          </Button>
                          {isSubmitting
                            && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </Box>
                    </form>
                  )}
                </Formik>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  autoHideDuration={6000}
                  open={openEmailDone}
                  onClose={handleEmailDoneClose}
                  message="Email sent successfully."
                  key="topcenter"
                  action={
                    (
                      <>
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => {
                            navigate(`/app/subscriptions/details?id=${subscriptionDetail.subscription.header.subscriptionid}`, { replace: true });
                          }}
                        >
                          Back
                        </Button>
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          className={classes.close}
                          onClick={handleEmailDoneClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                     )
                  }
                />
              </CardContent>
            </Card>
          </Container>
        </Box>
      </Page>
    )
  );
};

export default PayEmailView;

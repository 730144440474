import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import IdleTimer from "react-idle-timer";
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import SessionContext from 'src/libs/SessionContext';
import { adminValidateToken } from 'src/libs/AdminServices';

const App = () => {
  const _userSession = {
    isAuthenticated: false,
    isAuthenticating: true,
    showTimeOutLogin: false,
    UserID: -1,
    UserName: '',
    UserType: '',
    UserEmail: '',
    Avatar: ''
  };

  const [userSession, setUserSession] = useState(_userSession);
  const value = { userSession, setUserSession };

  const routing = useRoutes(routes(userSession));

  const clearUserSession = () => {
    const tmpUserSession = {
      isAuthenticated: false,
      isAuthenticating: false,
      showTimeOutLogin: false,
      UserID: -1,
      UserName: '',
      UserType: '',
      UserEmail: '',
      Avatar: '',
      CustomerID: '',
      Market: ''
    };
    setUserSession(tmpUserSession);
    sessionStorage.clear();
  };

  const ValidateToken = async () => {
    try {
      const res = await adminValidateToken();
      return res;
    } catch (e) {
      alert('validatetoken error');
    }

    return false;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const isAuthenticated = sessionStorage.getItem('isAuthenticated', 'true');

        if (isAuthenticated === 'true') {
          const res = await ValidateToken();
          if (res && res.status === 200) {
            const tmpUserSession = {
              isAuthenticated: true,
              isAuthenticating: false,
              showTimeOutLogin: false,
              UserID: res.userid,
              UserName: res.username,
              UserType: res.usertype,
              UserEmail: res.email,
              Avatar: res.avatar,
              CustomerID: res.customerid,
              Market: res.market
            };
            setUserSession(tmpUserSession);
          } else {
            alert('Session Expired');
            clearUserSession();
            setUserSession({ ...userSession, isAuthenticating: false });
          }
        } else {
          setUserSession({ ...userSession, isAuthenticating: false });
        }
      } catch (e) {
        if (e !== 'No current user') {
          setUserSession({ ...userSession, isAuthenticating: false });
          alert(e);
        }
      }
    }
    fetchData();
  }, []);

  const onIdle = (event) => {
    if (userSession.isAuthenticated) {
      setUserSession({ ...userSession, showTimeOutLogin: true });
    }
  };

  return (
    !userSession.isAuthenticating && (
      <SessionContext.Provider value={value}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
          <IdleTimer
            element={document}
            onIdle={onIdle}
            timeout={1000 * 60 * 15}
          />
        </ThemeProvider>
      </SessionContext.Provider>
    )
  );
};

export default App;

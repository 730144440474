import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import queryString from 'query-string';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Link,
  Typography,
  TextField,
  FormHelperText,
  Snackbar,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import { adminGetSubscriptionDetail, adminPayBill } from 'src/libs/AdminServices';
import SubscriptionInfo from 'src/views/subscription/SubscriptionDetailsView/SubscriptionInfo';
import BillingInfo from './BillingInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

function NumberFieldHooks(props) {
  const {
    name,
    label,
    variant,
    format,
    error,
    helperText,
    fullWidth
  } = props;
  const [field] = useField(name);

  return (
    <NumberFormat
      {...field}
      label={label}
      customInput={TextField}
      format={format}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      variant={variant}
    />
  );
}

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = `0${val}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';
    // this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function cardExpiry(val) {
  const month = limit(val.substring(0, 2), '12');
  const year = val.substring(2, 4);

  return month + (year.length ? `/${year}` : '');
}

const PayBillView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [loadingDetail, setLoadingDetail] = React.useState(true);
  const [subscriptionDetail, setSubscriptionDetail] = React.useState({});
  const [openPayDone, setOpenPayDone] = React.useState(false);
  const [openPayResponseDialog, setOpenPayResponseDialog] = React.useState(false);
  const [payResponseMessage, setPayResponseMessage] = React.useState({
    success: false,
    title: 'Error',
    message: ''
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchData() {
      const qstr = queryString.parse(search);

      try {
        const res = await adminGetSubscriptionDetail(qstr.sid, qstr.bid);
        if (res && res.status === 200) {
          setSubscriptionDetail(res);
        } else if (res.status === 401) {
          alert('Sorry, your session has expired. Please log in again.');
          setUserSession({ ...userSession, isAuthenticated: false });
          sessionStorage.clear();
        } else {
          alert(res.error);
        }
        setLoadingDetail(false);
      } catch (e) {
        console.log(e);
        setLoadingDetail(false);
      }
    }
    fetchData();
  }, []);

  const handlePayResponseDialogOpen = () => {
    setOpenPayResponseDialog(true);
  };

  const handlePayResponseDialogClose = () => {
    setOpenPayResponseDialog(false);
    if (payResponseMessage.success) {
      navigate(`/app/subscriptions/details?id=${subscriptionDetail.subscription.header.subscriptionid}`, { replace: true });
    }
  };

  const handlePayDoneClose = () => {
    setOpenPayDone(false);
  };

  const PayBill = async (values) => {
    const value = queryString.parse(search);

    try {
      const dto = {
        billingid: subscriptionDetail.subscription.billings[0].billingid,
        cardholder: values.cardholder,
        cardnumber: values.cardnumber,
        cvc: values.cvc,
        expdate: values.expdate,
        userid: userSession.UserID,
      };
      console.log(dto);
      const res = await adminPayBill(dto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  return (
    !loadingDetail && subscriptionDetail.status === 200 && (
      <Page
        className={classes.root}
        title="Billing"
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <Container maxWidth="lg">
            <>
              <SubscriptionInfo
                customerinfo={subscriptionDetail.customerinfo}
                subscription={subscriptionDetail.subscription}
              />
              <br />
              <BillingInfo
                billing={subscriptionDetail.subscription.billings[0]}
              />
              <Card>
                <CardHeader
                  className={classes.cardtitle}
                  title="Payment Details"
                  titleTypographyProps={{ variant: 'h3' }}
                />
                <Divider />
                <CardContent>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      cardnumber: '',
                      cardholder: '',
                      cvc: '',
                      expdate: ''
                    }}
                    validationSchema={
                      Yup.object().shape({
                        cardnumber: Yup.string().max(30).required('Card number is required'),
                        cardholder: Yup.string().max(100).required('Card holder is required'),
                        cvc: Yup.string().max(4).required('CVC is required'),
                        expdate: Yup.string().max(10).required('expiry date is required')
                      })
                    }
                    onSubmit={async (values, {
                      setSubmitting,
                      setFieldError
                    }) => {
                      const res = await PayBill(values);
                      if (res) {
                        if (res.status === 200) {
                          setPayResponseMessage({
                            success: true,
                            title: 'Information',
                            message: res.message
                          });
                          handlePayResponseDialogOpen(true);
                        } else {
                          setPayResponseMessage({
                            success: false,
                            title: 'Error',
                            message: res.message
                          });
                          handlePayResponseDialogOpen(true);
                        }
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <NumberFieldHooks
                              error={Boolean(touched.cardnumber && errors.cardnumber)}
                              fullWidth
                              helperText={touched.cardnumber && errors.cardnumber}
                              label="Card Number"
                              margin="normal"
                              value={values.cardnumber}
                              name="cardnumber"
                              type="text"
                              format="#### #### #### ####"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.cardholder && errors.cardholder)}
                              fullWidth
                              helperText={touched.cardholder && errors.cardholder}
                              label="Card Holder"
                              margin="normal"
                              name="cardholder"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.cardholder}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                          >
                            <NumberFieldHooks
                              error={Boolean(touched.cvc && errors.cvc)}
                              helperText={touched.cvc && errors.cvc}
                              label="CVC"
                              margin="normal"
                              value={values.cvc}
                              name="cvc"
                              type="text"
                              format="###"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                            {'  '}
                            <NumberFieldHooks
                              error={Boolean(touched.expdate && errors.expdate)}
                              helperText={touched.expdate && errors.expdate}
                              label="Valid Thru (MM/YY)"
                              margin="normal"
                              value={values.expdate}
                              name="expdate"
                              type="text"
                              format={cardExpiry}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                          >
                            <div>
                              <img alt="Visa" src="/static/images/cards/visa.png" />
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <img alt="Master" src="/static/images/cards/master.png" />
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <img alt="JCB" src="/static/images/cards/jcb.png" />
                            </div>
                          </Grid>
                        </Grid>
                        <Box my={2}>
                          <div className={classes.wrapper}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {isSubmitting ? 'Please wait ...' : 'Pay now'}
                            </Button>
                            {isSubmitting
                              && <CircularProgress size={24} className={classes.buttonProgress} />}
                          </div>
                        </Box>
                      </form>
                    )}
                  </Formik>
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={3000}
                    open={openPayDone}
                    onClose={handlePayDoneClose}
                    message="Payment process completed successfully."
                    key="topcenter"
                  />
                </CardContent>
              </Card>
              <Dialog
                fullWidth="true"
                maxWidth="sm"
                open={openPayResponseDialog}
                onClose={handlePayResponseDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <Typography variant="h3">
                    {payResponseMessage.title}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {payResponseMessage.message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handlePayResponseDialogClose} color="primary" autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          </Container>
        </Box>
      </Page>
    )
  );
};

export default PayBillView;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
}));

const BillingInfo = ({
  className,
  billing,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <>
      <Card>
        <CardHeader
          className={classes.cardtitle}
          title="Billing Details"
          titleTypographyProps={{ variant: 'h3' }}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper className={classes.paper}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Billing Date
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <Paper className={classes.paper}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  justifyContent="flex-end"
                >
                  {billing.billingdatedisplay}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Paper className={classes.paper}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Due Date
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <Paper className={classes.paper}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  justifyContent="flex-end"
                >
                  {billing.duedatedisplay}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="billing table">
              <TableHead>
                <TableRow>
                  <TableCell>Charge Description</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billing.items.map((billitem) => (
                  <TableRow key={billitem.itemid}>
                    <TableCell>{billitem.itemdescription}</TableCell>
                    <TableCell align="right">{billitem.outstandingamountdisplay}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right">Billing Total</TableCell>
                  <TableCell align="right">{billing.outstandingamountdisplay}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

BillingInfo.propTypes = {
  className: PropTypes.string,
  billing: PropTypes.object.isRequired
};

export default BillingInfo;

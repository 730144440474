import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import axios from 'axios';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import SessionContext from 'src/libs/SessionContext';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [openUpload, setOpenUpload] = useState(false);

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const handleSaveUpload = async (files) => {
    const formData = new FormData();
    let fileName = '';
    let index = 0;
    files.forEach((file) => {
      index++;
      formData.append(`file${index}`, file);
      const tmp = file.name.split('.');
      if (tmp.length > 1) {
        fileName = [userSession.UserID, tmp[1]].join('.');
      } else {
        fileName = file.name;
      }
    });
    formData.append('filename', fileName);
    formData.append('userid', userSession.UserID);
    /*
    const res = await fetch('/api/admin/UploadUserImage', {
      method: 'POST',
      body: formData,
    });

    const data = await res.json();

    if (data.status === 200) {
      setUserSession({ ...userSession, Avatar: data.avatar });
    }
    */
    try {
      const res = await axios.post('/api/admin/UploadUserImage', formData);
      if (res.data.status === 200) {
        setUserSession({ ...userSession, Avatar: res.data.avatar });
      }
    } catch (ex) {
      console.log(ex);
    }

    setOpenUpload(false);
    return false;
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={userSession.Avatar}
          >
            {getInitials(userSession.UserName)}
          </Avatar>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {userSession.UserName}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleOpenUpload}
        >
          Upload picture
        </Button>
        <DropzoneDialog
          open={openUpload}
          onSave={handleSaveUpload}
          acceptedFiles={['image/*']}
          showPreviews
          cancelButtonText="cancel"
          submitButtonText="submit"
          maxFileSize={5000000}
          onClose={handleCloseUpload}
          showFileNamesInPreview
        />
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;

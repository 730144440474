import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Snackbar,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import SessionContext from 'src/libs/SessionContext';
import { adminUpdateUserPassword } from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [initvalues, setInitValues] = useState({
    password: '',
    confirmPassword: ''
  });

  const UpdateUserPassword = async (values) => {
    try {
      const UserDto = {
        userid: userSession.UserID,
        password: values.password
      };

      const res = await adminUpdateUserPassword(UserDto);

      if (res.status === 200) {
        setOpenSaveDone(true);
      } else {
        alert(res.error);
      }
    } catch (e) {
      alert('error');
    }
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initvalues}
        validationSchema={Yup.object().shape({
          password: Yup.string().min(8).max(20).required('Password is required')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
              "Must Contain 8 Characters, at least one uppercase, one lowercase, one number and one special character"
            ),
          confirmPassword: Yup.string().min(8).max(20).required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await UpdateUserPassword(values);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader="Update password"
                title="Password"
                titleTypographyProps={{ variant: 'h2' }}
              />
              <Divider />
              <CardContent>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  label="Password"
                  helperText={touched.password && errors.password}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Confirm password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
              </CardContent>
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <div className={classes.wrapper}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {isSubmitting ? 'Please wait ...' : 'Update'}
                  </Button>
                  {isSubmitting
                    && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={openSaveDone}
        onClose={handleSaveDoneClose}
        message="Password updated successfully"
        key="topcenter"
      />
    </div>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;

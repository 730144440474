import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  TextField,
  Snackbar,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import SessionContext from 'src/libs/SessionContext';
import Page from 'src/components/Page';
import { adminValidatePaymentlink, adminActivateCustomerUserAccount } from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const PayLinkHandler = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(true);
  const [billingRef, setBillingRef] = useState({});
  const navigate = useNavigate();
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const [initvalues, setInitValues] = useState({
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const value = queryString.parse(search);

        const res = await adminValidatePaymentlink(value.token);
        if (res) {
          if (res.status !== 200) {
            alert(res.error);
          } else if (res.action === 'login') {
            navigate(`/handler/login?page=pay&sid=${res.subscriptionid}&bid=${res.billingid}`, { replace: true });
          } else if (res.action === 'tokenexpired') {
            alert("Token expired");
          }
        }
        setBillingRef(res);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  const UpdateUserPassword = async (values) => {
    const value = queryString.parse(search);

    try {
      const passwordinfo = {
        id: billingRef.customerid,
        token: billingRef.activatetoken,
        password: values.password,
        autologin: 'Y'
      };

      const res = await adminActivateCustomerUserAccount(passwordinfo);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  return (
    !isLoading && (
      <Page
        className={classes.root}
        title="Payment"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <div>
              <Formik
                enableReinitialize
                initialValues={initvalues}
                validationSchema={Yup.object().shape({
                  password: Yup.string().min(8).max(20).required('Password is required')
                    .matches(
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
                      "Must Contain 8 Characters, at least one uppercase, one lowercase, one number and one special character"
                    ),
                  confirmPassword: Yup.string().min(8).max(20).required('Confirm password is required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  const res = await UpdateUserPassword(values);
                  if (res) {
                    if (res.status === 200) {
                      setOpenSaveDone(true);

                      sessionStorage.setItem('isAuthenticated', 'true');

                      const _userSession = {
                        isAuthenticated: true,
                        isAuthenticating: false,
                        showTimeOutLogin: false,
                        UserID: res.logininfo.userid,
                        UserName: res.logininfo.username,
                        UserType: res.logininfo.usertype,
                        Avatar: res.logininfo.avatar,
                        CustomerID: res.logininfo.customerid,
                        Market: res.logininfo.market
                      };

                      setUserSession(_userSession);

                      navigate(`/app/subscriptions/payform?sid=${billingRef.subscriptionid}&bid=${billingRef.billingid}`, { replace: true });
                    } else {
                      alert(res.error);
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form
                    onSubmit={handleSubmit}
                  >
                    <Card>
                      <CardHeader
                        title="Set Password"
                        titleTypographyProps={{ variant: 'h2' }}
                      />
                      <Divider />
                      <CardContent>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          label="Password"
                          helperText={touched.password && errors.password}
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password}
                          variant="outlined"
                        />
                        <TextField
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          fullWidth
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          label="Confirm password"
                          margin="normal"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.confirmPassword}
                          variant="outlined"
                        />
                      </CardContent>
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        p={2}
                      >
                        <div className={classes.wrapper}>
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            color="primary"
                            variant="contained"
                          >
                            {isSubmitting ? 'Please wait ...' : 'Update'}
                          </Button>
                          {isSubmitting
                            && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </Box>
                    </Card>
                  </form>
                )}
              </Formik>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                open={openSaveDone}
                onClose={handleSaveDoneClose}
                message="Password updated successfully"
                key="topcenter"
              />
            </div>
          </Container>
        </Box>
      </Page>
    )
  );
};

export default PayLinkHandler;

import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import Page from 'src/components/Page';
import { adminGetMarket, adminRegisterCustomer } from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const [openMarket, setOpenMarket] = React.useState(false);
  const [markets, setMarkets] = React.useState([]);
  const loading = openMarket && markets.length === 0;
  const [regDone, setRegDone] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await adminGetMarket();
      console.log(res);
      if (active) {
        setMarkets(res);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!openMarket) {
      setMarkets([]);
    }
  }, [openMarket]);

  const RegisterCustomer = async (values) => {
    try {
      const CustomerDto = {
        name: values.companyName,
        email: values.email,
        contactperson: values.contactperson,
        phone: values.phone,
        market: values.market.marketCode
      };

      const res = await adminRegisterCustomer(CustomerDto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  if (regDone) {
    return (
      <RegisterDone />
    );
  }

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize
            initialValues={{
              email: '',
              companyName: '',
              contactperson: '',
              phone: '',
              market: {},
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                contactperson: Yup.string().max(50),
                phone: Yup.string().max(50),
                companyName: Yup.string().max(255).required('Company name is required'),
                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={async (values, {
              setSubmitting,
              setFieldError
            }) => {
              if (!values.market || !values.market.marketCode) {
                setFieldError('market', 'Market is required');
              } else {
                const res = await RegisterCustomer(values);

                if (res) {
                  if (res.status === 200) {
                    setOpenSaveDone(true);
                    setRegDone(true);
                    // navigate('/app/dashboard', { replace: true });
                  } else if (res.errortype === 'name') {
                    setFieldError('companyName', res.error);
                  } else {
                    setFieldError('email', res.error);
                  }
                }
              }
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  helperText={touched.companyName && errors.companyName}
                  label="Company name"
                  margin="normal"
                  name="companyName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyName}
                  variant="outlined"
                />
                <Autocomplete
                  id="market"
                  name="market"
                  open={openMarket}
                  onOpen={() => {
                    setOpenMarket(true);
                  }}
                  onClose={() => {
                    setOpenMarket(false);
                  }}
                  options={markets}
                  loading={loading}
                  getOptionLabel={(option) => option.marketName}
                  value={values.marketCode}
                  onBlur={handleBlur}
                  onChange={(e, value) => {
                    setFieldValue("market", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="market"
                      label="Market"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.market && errors.market)}
                      helperText={touched.market && errors.market}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <TextField
                  error={Boolean(touched.contactperson && errors.contactperson)}
                  fullWidth
                  helperText={touched.contactperson && errors.contactperson}
                  label="Contact Person"
                  margin="normal"
                  name="contactperson"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contactperson}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <div className={classes.wrapper}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? 'Please wait ...' : 'Sign up now'}
                    </Button>
                    {isSubmitting
                      && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={3000}
            open={openSaveDone}
            onClose={handleSaveDoneClose}
            message="Registration saved successfully"
            key="topcenter"
          />
        </Container>
      </Box>
    </Page>
  );
};

const RegisterDone = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Thanks for your registration,&nbsp;
            the account activation email will be sent to your registered email,&nbsp;
            please click the link in the email and activate your account within 12 hours.
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            align="right"
          >
            <Link
              component={RouterLink}
              to="/login"
              variant="h6"
            >
              Back
            </Link>
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default RegisterView;

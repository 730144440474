import moment from 'moment';
import setupInterceptorsTo from "src/libs/Interceptors";
import axios from "axios";
import Configuration from '../Configuration';

async function callXmlAPI(serviceName, postdata) {
  const xmlAxios = setupInterceptorsTo(axios.create());

  let data = null;

  if (postdata) {
    const res = await xmlAxios.post(`${Configuration.adminAPIUrl}/${serviceName}`,
      JSON.stringify(postdata));
    data = res.data;
  } else {
    const res = await xmlAxios.post(`${Configuration.adminAPIUrl}/${serviceName}`);
    data = res.data;
  }

  return data;
}

export async function adminValidateUser(_email, _password) {
  const postdata = {
    email: _email,
    password: _password
  };

  return callXmlAPI('ValidateUser', postdata);
}

export async function adminLogout() {
  return callXmlAPI('Logout');
}

export async function adminValidateToken() {
  return callXmlAPI('ValidateToken');
}

export async function adminGetUserInfo() {
  return callXmlAPI('GetUserInfo');
}

export async function adminUpdateUserInfo(_userinfo) {
  const postdata = _userinfo;

  return callXmlAPI('UpdateUserInfo', postdata);
}

export async function adminUpdateUserPassword(_userinfo) {
  const postdata = _userinfo;

  return callXmlAPI('UpdateUserPassword', postdata);
}

export async function adminRegisterCustomer(_customerinfo) {
  const postdata = _customerinfo;

  return callXmlAPI('RegisterCustomer', postdata);
}

export async function adminGetCustOrderFormInfo(_custno) {
  const postdata = {
    custno: _custno
  };

  return callXmlAPI('GetCustOrderFormInfo', postdata);
}

export async function adminUpdateCustomer(_customerinfo) {
  const postdata = _customerinfo;

  return callXmlAPI('UpdateCustomer', postdata);
}

export async function adminGetCustomerInfoByUser() {
  return callXmlAPI('GetCustomerInfoByUser');
}

export async function adminGetTrackingCustomerInfo() {
  return callXmlAPI('GetTrackingCustomerInfo');
}

export async function adminGetCustomerInfo(_custid) {
  const postdata = {
    id: _custid,
  };

  return callXmlAPI('GetCustomerInfo', postdata);
}

export async function adminGetMarket() {
  return callXmlAPI('GetMarket');
}

export async function adminGetTimeZone() {
  return callXmlAPI('GetTimeZone');
}

export async function adminActivateCustomerUserAccount(_passwordinfo) {
  const postdata = _passwordinfo;

  return callXmlAPI('ActivateCustomerUserAccount', postdata);
}

export async function adminSetPassword(_passwordinfo) {
  const postdata = _passwordinfo;

  return callXmlAPI('SetPassword', postdata);
}

export async function adminValidateForgotPasswordToken(_tokeninfo) {
  const postdata = _tokeninfo;

  return callXmlAPI('ValidateForgotPasswordToken', postdata);
}

export async function adminValidateRegistrationToken(_tokeninfo) {
  const postdata = _tokeninfo;

  return callXmlAPI('ValidateRegistrationToken', postdata);
}

export async function adminGetPricingInfo(_product, _market) {
  const postdata = {
    product: _product,
    market: _market
  };

  return callXmlAPI('GetPricingInfo', postdata);
}

export async function adminCreateSubscription(_header, _items) {
  _header.periodfromstring = moment(_header.periodfrom).format('YYYY/MM/DD');
  _header.periodtostring = moment(_header.periodto).format('YYYY/MM/DD');

  const postdata = {
    header: _header,
    items: _items
  };

  return callXmlAPI('CreateSubscription', postdata);
}

export async function adminGetBillingRequestList(_requestid) {
  const postdata = {
    requestid: _requestid
  };

  return callXmlAPI('GetBillingRequestList', postdata);
}

export async function adminGetBillingRequestExcel(_searchPnl) {
  const postdata = {
    datefrom: moment(_searchPnl.periodfrom).format('YYYY/MM/DD'),
    dateto: moment(_searchPnl.periodto).format('YYYY/MM/DD')
  };

  return callXmlAPI('GetBillingRequestExcel', postdata);
}

export async function adminGetBillingRequestInfo(_requestid) {
  const postdata = {
    requestid: _requestid
  };

  return callXmlAPI('GetBillingRequestInfo', postdata);
}

export async function adminUpdateBillingRequest(_billingreqinfo) {
  const postdata = _billingreqinfo;
  console.log(_billingreqinfo);
  return callXmlAPI('UpdateBillingRequest', postdata);
}

export async function adminGetCustTaskList(_customername, _includedone) {
  const postdata = {
    customerName: _customername,
    bIncludeDoneTask: _includedone
  };

  return callXmlAPI('GetCustTaskList', postdata);
}

export async function adminGetCustTaskInfo(_taskid) {
  const postdata = {
    id: _taskid,
  };

  return callXmlAPI('GetCustTaskInfo', postdata);
}

export async function adminGetUploadedFile(_custtaskinfo) {
  const postdata = _custtaskinfo;

  return callXmlAPI('GetUploadedFile', postdata);
}

export async function adminGetCustTaskAction(_taskid, _seqno) {
  const postdata = {
    taskid: _taskid,
    seqno: _seqno
  };

  return callXmlAPI('GetCustTaskAction', postdata);
}

export async function adminUpdateCustTaskAction(_custtaskinfo) {
  const postdata = _custtaskinfo;

  return callXmlAPI('UpdateCustTaskAction', postdata);
}

export async function adminUpdateCustTask(_custtaskinfo) {
  const postdata = _custtaskinfo;

  return callXmlAPI('UpdateCustTask', postdata);
}

export async function adminDeleteCustTaskUploadedFile(_uploadedFiledto) {
  const postdata = _uploadedFiledto;

  return callXmlAPI('DeleteCustTaskUploadedFile', postdata);
}

export async function adminDeleteCustTaskAction(_actiondto) {
  const postdata = _actiondto;

  return callXmlAPI('DeleteCustTaskAction', postdata);
}

export async function adminGetSubscriptionList(_customerid) {
  const postdata = {
    customerid: _customerid
  };

  return callXmlAPI('GetSubscriptionList', postdata);
}

export async function adminGetSubscriptionDetail(_subscriptionid, _billingid) {
  const postdata = {
    subscriptionid: _subscriptionid,
    billingid: _billingid
  };

  return callXmlAPI('GetSubscriptionDetail', postdata);
}

export async function adminGetSubscriptionDetailForEmail(_subscriptionid, _billingid) {
  const postdata = {
    subscriptionid: _subscriptionid,
    billingid: _billingid
  };

  return callXmlAPI('GetSubscriptionDetailForEmail', postdata);
}

export async function adminSendEmail(_email) {
  const postdata = _email;

  return callXmlAPI('SendEmail', postdata);
}

export async function adminValidatePaymentlink(_token) {
  const postdata = {
    token: _token
  };

  return callXmlAPI('ValidatePaymentlink', postdata);
}

export async function adminPayBill(_dto) {
  const postdata = _dto;

  return callXmlAPI('PayBill', postdata);
}

export async function adminSendForgotPasswordEmail(_dto) {
  const postdata = _dto;

  return callXmlAPI('SendForgotPasswordEmail', postdata);
}

import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Snackbar,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import { adminSendForgotPasswordEmail } from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const ForgotPassword = ({ className, ...rest }) => {
  const classes = useStyles();
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const navigate = useNavigate();
  const [initvalues, setInitValues] = useState({
    password: '',
    confirmPassword: ''
  });

  const SendForgotPasswordEmail = async (values) => {
    try {
      const Dto = {
        email: values.email
      };

      const res = await adminSendForgotPasswordEmail(Dto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <div>
            <Formik
              enableReinitialize
              initialValues={initvalues}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(50).required('Email is required')
              })}
              onSubmit={async (values, {
                setSubmitting,
                setFieldError
              }) => {
                const res = await SendForgotPasswordEmail(values);
                if (res.status === 200) {
                  setOpenSaveDone(true);
                } else {
                  setFieldError('email', res.error);
                }
                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className={clsx(classes.root, className)}
                  {...rest}
                >
                  <Card>
                    <CardHeader
                      className={classes.cardtitle}
                      title="Forgot Password"
                      titleTypographyProps={{ variant: 'h2' }}
                    />
                    <Divider />
                    <CardContent>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Input the email address you used to register.
                        We’ll email you a link to a page where you can create a new password.
                      </Typography>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </CardContent>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      p={2}
                    >
                      <div className={classes.wrapper}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          {isSubmitting ? 'Please wait ...' : 'Submit'}
                        </Button>
                        {isSubmitting
                          && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                      <div className={classes.wrapper}>
                        <Button
                          disabled={isSubmitting}
                          color="primary"
                          variant="contained"
                          onClick={() => { navigate('/login', { replace: true }); }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={3000}
              open={openSaveDone}
              onClose={handleSaveDoneClose}
              message="Reset password email has been sent successfully"
              key="topcenter"
            />
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPassword;

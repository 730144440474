import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Container,
  Button,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import { adminGetBillingRequestList } from 'src/libs/AdminServices';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RequestListView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [billingreqs, setBillingReqs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchPnl, setSearchPnl] = useState({
    periodfrom: new Date(),
    periodto: new Date(),
    billperiodfrom: new Date(),
    billperiodto: new Date(),
    requeststatus: 'All',
    custname: ''
  });

  const handleFromDateChange = (date) => {
    setSearchPnl({
      ...searchPnl,
      periodfrom: date
    });
  };

  const handleToDateChange = (date) => {
    setSearchPnl({
      ...searchPnl,
      periodto: date
    });
  };

  const handleBillFromDateChange = (date) => {
    setSearchPnl({
      ...searchPnl,
      billperiodfrom: date
    });
  };

  const handleBillToDateChange = (date) => {
    setSearchPnl({
      ...searchPnl,
      billperiodto: date
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await adminGetBillingRequestList(null);
        if (res && res.status === 200) {
          setBillingReqs(res.billingreqs);
        } else if (res.status === 401) {
          alert('Sorry, your session has expired. Please log in again.');
          setUserSession({ ...userSession, isAuthenticated: false });
          sessionStorage.clear();
        } else {
          alert(res.error);
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    !isLoading && (
      <Page
        className={classes.root}
        title="Billing Request"
      >
        <Container maxWidth={false}>
          <Toolbar />

          <Formik
            enableReinitialize
            initialValues={{
              periodfrom: searchPnl.periodfrom,
              periodto: searchPnl.periodto,
              billperiodfrom: searchPnl.billperiodfrom,
              billperiodto: searchPnl.billperiodto,
              requeststatus: searchPnl.requeststatus,
              custname: searchPnl.custname
            }}
            onSubmit={async (values, {
              setSubmitting,
              setFieldError
            }) => {
              if (searchPnl.periodto <= searchPnl.periodfrom) {
                setFieldError('periodto', 'To date should be later than from date');
              } else {
                const periodfrom = moment(searchPnl.periodfrom).format('YYYY/MM/DD');
                const periodto = moment(searchPnl.periodto).format('YYYY/MM/DD');
                const billperiodfrom = moment(searchPnl.billperiodfrom).format('YYYY/MM/DD');
                const billperiodto = moment(searchPnl.billperiodto).format('YYYY/MM/DD');
                const url = `/api/admin/GetBillingRequestExcel?datefrom=${periodfrom}&dateto=${periodto}&billdatefrom=${billperiodfrom}&billdateto=${billperiodto}&status=${values.requeststatus}&custname=${values.custname}`;
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                  'download',
                  `${Date.now()}.xlsx`,
                );

                document.body.appendChild(link);
                link.click();

                link.remove();
              }
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <>
                  <Card>
                    <CardContent>
                      <>
                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            md={2}
                            xs={12}
                          >
                            <Typography variant="h3" component="h2">
                              Request Period
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={10}
                            xs={12}
                          >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <Grid container justify="space-around">
                                <KeyboardDatePicker
                                  error={Boolean(touched.periodfrom && errors.periodfrom)}
                                  helperText={touched.periodfrom && errors.periodfrom}
                                  margin="normal"
                                  id="periodfrom"
                                  name="periodfrom"
                                  label="From"
                                  format="DD MMM YYYY"
                                  onChange={handleFromDateChange}
                                  onBlur={handleBlur}
                                  value={values.periodfrom}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                                <KeyboardDatePicker
                                  error={Boolean(touched.periodto && errors.periodto)}
                                  helperText={touched.periodto && errors.periodto}
                                  margin="normal"
                                  id="periodto"
                                  name="periodto"
                                  label="To"
                                  format="DD MMM YYYY"
                                  onChange={handleToDateChange}
                                  onBlur={handleBlur}
                                  value={values.periodto}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={12}
                          >
                            <Typography variant="h3" component="h2">
                              Billing Period
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={10}
                            xs={12}
                          >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <Grid container justify="space-around">
                                <KeyboardDatePicker
                                  error={Boolean(touched.billperiodfrom && errors.billperiodfrom)}
                                  helperText={touched.billperiodfrom && errors.billperiodfrom}
                                  margin="normal"
                                  id="billperiodfrom"
                                  name="billperiodfrom"
                                  label="From"
                                  format="DD MMM YYYY"
                                  onChange={handleBillFromDateChange}
                                  onBlur={handleBlur}
                                  value={values.billperiodfrom}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                                <KeyboardDatePicker
                                  error={Boolean(touched.billperiodto && errors.billperiodto)}
                                  helperText={touched.billperiodto && errors.billperiodto}
                                  margin="normal"
                                  id="billperiodto"
                                  name="billperiodto"
                                  label="To"
                                  format="DD MMM YYYY"
                                  onChange={handleBillToDateChange}
                                  onBlur={handleBlur}
                                  value={values.billperiodto}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid
                            item
                            md={9}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.custname && errors.custname)}
                              fullWidth
                              helperText={touched.custname && errors.custname}
                              label="Customer Name"
                              margin="normal"
                              name="custname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.custname}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.requeststatus && errors.requeststatus)}
                              fullWidth
                              helperText={touched.requeststatus && errors.requeststatus}
                              label="Status"
                              margin="normal"
                              name="requeststatus"
                              select
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.requeststatus}
                              variant="outlined"
                            >
                              <MenuItem value="All">All</MenuItem>
                              <MenuItem value="R">Raise</MenuItem>
                              <MenuItem value="C">Confirm</MenuItem>
                              <MenuItem value="I">Invoiced</MenuItem>
                              <MenuItem value="V">Void</MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>
                        <br />
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          p={2}
                        >
                          <div className={classes.wrapper}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              variant="contained"
                              type="submit"
                            >
                              {isSubmitting ? 'Please wait ...' : 'Export'}
                            </Button>
                            {isSubmitting
                              && <CircularProgress size={24} className={classes.buttonProgress} />}
                          </div>
                        </Box>
                      </>
                    </CardContent>
                  </Card>
                </>
              </form>
            )}
          </Formik>

          <Box mt={3}>
            <Results billingreqs={billingreqs} />
          </Box>
        </Container>
      </Page>
    )
  );
};

export default RequestListView;

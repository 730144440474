import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    createdAt: '27/03/2019',
    description: 'Everything you need to power-up your business growth and streamline your operations.',
    media: '/static/images/products/PowerSuite.png',
    title: 'PowerSuite',
    productcode: 'PS',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '31/03/2019',
    description: 'Redefine corporate travel experience. iNet Corporate enables corporate customers to manage travel policies while making reservations with compliance & cost control. Seamless integration with travel agents\' mid & back office system to achieve end - to - end automation.',
    media: '/static/images/products/iNetCorp.png',
    title: 'iNet Corporate',
    productcode: 'IC',
    totalDownloads: '625'
  }
];

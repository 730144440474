import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Checkbox,
  Typography,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Snackbar,
  Switch,
  FormControlLabel,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import {
  adminGetCustTaskInfo,
  adminUpdateCustTask
} from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const TaskDetailsView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const [loadingTaskDetail, setLoadingTaskDetail] = React.useState(true);
  const [mode, setMode] = React.useState('Add');
  const navigate = useNavigate();
  const bIsAdmin = (userSession.UserType === 'Admin');
  const [initvalues, setInitValues] = useState({
    id: '',
    customerName: '',
    trackCustNo: '',
    market: '',
    bCompleted: false,
    requestid: ''
  });

  useEffect(() => {
    if (initvalues.id === '') {
      (async () => {
        const value = queryString.parse(search);
        const taskid = value.tid;

        if (!value.mode || value.mode !== 'add') {
          setMode('Edit');
          const res = await adminGetCustTaskInfo(taskid);
          if (res && res.status === 200 && res.custtasks && res.custtasks.length === 1) {
            const taskinfo = res.custtasks[0];

            setInitValues({
              id: taskid,
              customerName: taskinfo.customername,
              trackCustNo: taskinfo.trackcustno,
              market: taskinfo.market,
              bCompleted: taskinfo.bcompleted,
              requestid: taskinfo.requestid,
            });
          } else if (res.status === 401) {
            alert('Sorry, your session has expired. Please log in again.');
            setUserSession({ ...userSession, isAuthenticated: false });
            sessionStorage.clear();
          }
        }
        setLoadingTaskDetail(false);
      })();
    }
  }, [initvalues]);

  const handleDoneChange = (event) => {
    setInitValues({ ...initvalues, bCompleted: event.target.checked });
  };

  const UpdateCustTask = async (values) => {
    try {
      const CustomerDto = {
        id: values.id,
        customerName: values.customerName,
        trackCustNo: values.trackCustNo,
        market: values.market,
        bcompleted: values.bCompleted,
        requestid: values.requestid
      };

      const res = await adminUpdateCustTask(CustomerDto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  return (
    !loadingTaskDetail && (
      <Page
        className={classes.root}
        title="Customer Cabinet"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={initvalues}
              validationSchema={
                Yup.object().shape({
                  customerName: Yup.string().max(200),
                  trackCustNo: Yup.string().max(200),
                  market: Yup.string().max(100)
                })
              }
              onSubmit={async (values, {
                setSubmitting,
                setFieldError
              }) => {
                const res = await UpdateCustTask(values);

                if (res) {
                  if (res.status === 200) {
                    setOpenSaveDone(true);
                    if (mode === 'Add') {
                      navigate('/app/cabinet', { replace: true });
                    }
                  } else if (res.status === 401) {
                    alert('Sorry, your session has expired. Please log in again.');
                    setUserSession({ ...userSession, isAuthenticated: false });
                    sessionStorage.clear();
                  }
                }

                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader
                      className={classes.cardtitle}
                      title={mode === 'Add' ? 'Add Customer Cabinet' : 'Edit Customer Cabinet'}
                      titleTypographyProps={{ variant: 'h2' }}
                    />
                    <Divider />
                    <CardContent>
                      <TextField
                        error={Boolean(touched.customerName && errors.customerName)}
                        fullWidth
                        helperText={touched.customerName && errors.customerName}
                        label="Customer name"
                        margin="normal"
                        name="customerName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.customerName}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.trackCustNo && errors.trackCustNo)}
                        fullWidth
                        helperText={touched.trackCustNo && errors.trackCustNo}
                        label="Tracking Customer No."
                        margin="normal"
                        name="trackCustNo"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.trackCustNo}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.market && errors.market)}
                        fullWidth
                        helperText={touched.market && errors.market}
                        label="Market"
                        margin="normal"
                        name="market"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.market}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.requestid && errors.requestid)}
                        fullWidth
                        helperText={touched.requestid && errors.requestid}
                        label="Billing Request No."
                        margin="normal"
                        name="requestid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.requestid}
                        variant="outlined"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox checked={values.bCompleted} onChange={handleDoneChange} />
                        }
                        label="Done"
                      />
                      <Box my={2}>
                        <table border="0" cellPadding="2" cellSpacing="0" align="right">
                          <tr>
                            <td>
                              <div className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  onClick={() => navigate(`/app/cabinet`, { replace: true })}
                                >
                                  Back
                                </Button>
                              </div>
                            </td>
                            <td>
                              <div className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting}
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                >
                                  {isSubmitting ? 'Please wait ...' : 'Save'}
                                </Button>
                                {isSubmitting && (
                                  <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </Box>
                    </CardContent>
                  </Card>
                </form>
              )}
            </Formik>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={3000}
              open={openSaveDone}
              onClose={handleSaveDoneClose}
              message="Customer cabinet updated successfully"
              key="topcenter"
            />
          </Container>
        </Box>
      </Page>
    )
  );
};

export default TaskDetailsView;

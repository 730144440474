import React, { useState } from 'react';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tooltip,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import AttachFile from '@material-ui/icons/AttachFile';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import SessionContext from 'src/libs/SessionContext';
import {
  adminDeleteCustTaskAction,
  adminDeleteCustTaskUploadedFile,
  adminGetUploadedFile,
  adminGetCustOrderFormInfo
} from 'src/libs/AdminServices';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tabletitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [bShowOrderForm, setbShowOrderForm] = React.useState(false);
  const [orderFormList, setOrderFormList] = React.useState([]);
  const classes = useRowStyles();
  const navigate = useNavigate();
  const { userSession, setUserSession } = React.useContext(SessionContext);

  const actionRowDeleteOnClick = async (r, actionRow, index, e) => {
    if (window.confirm("Are you sure to delete this item?")) {
      try {
        const actionDto = {
          taskid: r.taskid,
          seqno: actionRow.seqno,
        };

        const res = await adminDeleteCustTaskAction(actionDto);
        if (res.status === 200) {
          window.location.reload(false);
          // e.target.parentNode.parentNode.parentNode.deleteRow(index);
        }
        return res;
      } catch (ex) {
        alert('error');
      }
    }
    return null;
  };

  const uploadedfileRowDeleteOnClick = async (r, uploadedFileRow, index, e) => {
    if (window.confirm("Are you sure to delete this file?")) {
      try {
        const uploadedFileDto = {
          taskid: r.taskid,
          seqno: uploadedFileRow.seqno,
        };

        const res = await adminDeleteCustTaskUploadedFile(uploadedFileDto);
        if (res.status === 200) {
          window.location.reload(false);
        }
        return res;
      } catch (ex) {
        alert('error');
      }
    }
    return null;
  };

  const markComplete = async (event, r) => {
    return true;
  };

  const openFile = async (r, uploadedFileRow, index, e) => {
    const a = document.createElement("a");
    e.preventDefault();

    try {
      const uploadedFileDto = {
        taskid: r.taskid,
        seqno: uploadedFileRow.seqno,
      };

      const res = await adminGetUploadedFile(uploadedFileDto);
      if (res.status === 200) {
        a.href = res.custtaskfile.uploadfile;
        a.download = res.custtaskfile.uploadfilename;
        a.click();
      }
      return res;
    } catch (ex) {
      alert('error');
    }

    return null;
  };

  const openOrderForm = async (r, e) => {
    e.preventDefault();

    document.getElementById("UserID").value = r.vieworderformuserid;
    document.getElementById("OrderFormID").value = r.ofno;
    document.getElementById("frmTrackingOrder").submit();

    return null;
  };

  const openBillingRequest = async (rid, e) => {
    e.preventDefault();

    navigate(`/app/billingrequest/details?rid=${rid}`, { replace: true });

    return null;
  };

  const opeDetails = async (r, Open, e) => {
    let tmp = [];

    e.preventDefault();

    setOpen(Open);

    if (Open && r.trackcustno && orderFormList.length === 0) {
      const res = await adminGetCustOrderFormInfo(r.trackcustno);
      if (res && res.status === 200) {
        console.log(res);
        tmp = res.orderforms;
        setOrderFormList(tmp);
        setbShowOrderForm(true);
      }
    }

    return null;
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={(e) => opeDetails(row, !open, e)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell><b>{row.taskid}</b></TableCell>
        <TableCell component="th" scope="row">
          <b>{row.createdatedisplay}</b>
        </TableCell>
        <TableCell component="th" scope="row" align="center" style={{ color: "#0026FF" }}>
          <b>{row.completion}</b>
        </TableCell>
        <TableCell component="th" scope="row" style={{ color: "#0026FF" }}>
          <b>{row.lastactiondatedisplay}</b>
        </TableCell>
        <TableCell><b>{row.lastactiontype}</b></TableCell>
        <TableCell><b>{row.customername}</b></TableCell>
        <TableCell>
          <b>{row.trackcustno}</b>
        </TableCell>
        <TableCell><b>{row.market}</b></TableCell>
        <TableCell align="center">
          {row.bcompleted ? <Check /> : <span />}
        </TableCell>
        <TableCell align="center">
          <Tooltip disableFocusListener disableTouchListener title="Edit">
            <IconButton aria-label="row" size="small" onClick={() => navigate(`/app/cabinet/details?tid=${row.taskid}`, { replace: true })}>
              <Edit />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 10 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginLeft={10}>
              {bShowOrderForm && (
                <Table size="small" aria-label="orderforms">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tabletitle}>Order Form Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Grid
                          container
                          spacing={2}
                        >
                          {orderFormList.map((orderformRow, index) => (
                            <Grid
                              item
                              md={2}
                              xs={6}
                            >
                              <b>
                                <NavLink
                                  className="navbar-item"
                                  activeClassName="is-active"
                                  to="#"
                                  onClick={(e) => openOrderForm(orderformRow, e)}
                                  exact
                                >
                                  {orderformRow.ofno}
                                </NavLink>
                              </b>
                            </Grid>
                          ))}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              {row.requestid && row.requestid !== "" && (
                <Table size="small" aria-label="billingrequest">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tabletitle}>Billing Request Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>
                          <NavLink
                            className="navbar-item"
                            activeClassName="is-active"
                            to="#"
                            onClick={(e) => openBillingRequest(row.requestid, e)}
                            exact
                          >
                            {row.requestid}
                          </NavLink>
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              <Table size="small" aria-label="uploadedfiles">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 150 }} className={classes.tabletitle}>
                      Upload Date
                    </TableCell>
                    <TableCell className={classes.tabletitle}>File Name</TableCell>
                    <TableCell className={classes.tabletitle}>Remark</TableCell>
                    <TableCell style={{ width: 50 }} className={classes.tabletitle}>
                      &nbsp;
                    </TableCell>
                    <TableCell style={{ width: 50 }} className={classes.tabletitle}>
                      <Tooltip disableFocusListener disableTouchListener title="Upload New File">
                        <IconButton aria-label="row" size="small" onClick={() => navigate(`/app/cabinet/uploadfile?tid=${row.taskid}&mode=add`, { replace: true })}>
                          <AttachFile className={classes.tabletitle} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.uploadedfiles.map((itemRow, index) => (
                    <TableRow key={itemRow.seqno}>
                      <TableCell>
                        {itemRow.uploaddatedisplay}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <NavLink
                          className="navbar-item"
                          activeClassName="is-active"
                          to="#"
                          onClick={(e) => openFile(row, itemRow, index, e)}
                          exact
                        >
                          {itemRow.uploadfilename}
                        </NavLink>
                      </TableCell>
                      <TableCell>{itemRow.uploadfileremark}</TableCell>
                      <TableCell>
                        <Tooltip disableFocusListener disableTouchListener title="Delete File">
                          <IconButton aria-label="row" size="small" onClick={(e) => uploadedfileRowDeleteOnClick(row, itemRow, index, e)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip disableFocusListener disableTouchListener title="Edit Remark">
                          <IconButton aria-label="row" size="small" onClick={(e) => navigate(`/app/cabinet/uploadfile?tid=${row.taskid}&seqno=${itemRow.seqno}`, { replace: true })}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <br />
              <Table size="small" aria-label="ActionItems">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 150 }} className={classes.tabletitle}>
                      Action Date
                    </TableCell>
                    <TableCell className={classes.tabletitle}>Type</TableCell>
                    <TableCell className={classes.tabletitle}>Description</TableCell>
                    <TableCell className={classes.tabletitle}>Status</TableCell>
                    <TableCell style={{ width: 50 }} className={classes.tabletitle}>
                      &nbsp;
                    </TableCell>
                    <TableCell style={{ width: 50 }} className={classes.tabletitle}>
                      <Tooltip disableFocusListener disableTouchListener title="Create New Action">
                        <IconButton aria-label="row" size="small" onClick={() => navigate(`/app/cabinet/actions?tid=${row.taskid}&seqno=0`, { replace: true })}>
                          <Add className={classes.tabletitle} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.actionitems.map((actionRow, index) => (
                    <TableRow key={actionRow.seqno}>
                      <TableCell>{actionRow.actiondatedisplay}</TableCell>
                      <TableCell>{actionRow.actiontype}</TableCell>
                      <TableCell>{actionRow.actiondescription}</TableCell>
                      <TableCell>{actionRow.actionstatus}</TableCell>
                      <TableCell>
                        <Tooltip disableFocusListener disableTouchListener title="Delete item">
                          <IconButton aria-label="row" size="small" onClick={(e) => actionRowDeleteOnClick(row, actionRow, index, e)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip disableFocusListener disableTouchListener title="Edit item">
                          <IconButton aria-label="row" size="small" onClick={(e) => navigate(`/app/cabinet/actions?tid=${row.taskid}&seqno=${actionRow.seqno}`, { replace: true })}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    taskid: PropTypes.string.isRequired,
    createdatedisplay: PropTypes.string.isRequired,
    customername: PropTypes.string.isRequired,
    trackcustno: PropTypes.string.isRequired,
    market: PropTypes.string.isRequired,
    bcompleted: PropTypes.bool.isRequired,
    uploadedfiles: PropTypes.arrayOf(
      PropTypes.shape({
        seqno: PropTypes.number.isRequired,
        uploadfilename: PropTypes.string.isRequired,
        uploadfile: PropTypes.string.isRequired,
        uploadfileremark: PropTypes.string.isRequired,
        uploaddatedisplay: PropTypes.string.isRequired,
      }),
    ).isRequired,
    actionitems: PropTypes.arrayOf(
      PropTypes.shape({
        seqno: PropTypes.number.isRequired,
        actiontype: PropTypes.string.isRequired,
        actiondescription: PropTypes.string.isRequired,
        actionstatus: PropTypes.string.isRequired,
        actiondatedisplay: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

const Results = ({ className, custtasks, ...rest }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Creation Date</TableCell>
            <TableCell>Completion</TableCell>
            <TableCell>Action Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Tracking Customer No.</TableCell>
            <TableCell>Market</TableCell>
            <TableCell align="center">Done</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {custtasks.map((row) => (
            <Row key={row.taskid} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  billings: PropTypes.array.isRequired
};

export default Results;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Snackbar,
  Switch,
  FormControlLabel,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import {
  adminGetCustTaskAction,
  adminUpdateCustTaskAction
} from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const TaskActionView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const [loadingTaskAction, setLoadingTaskAction] = React.useState(true);
  const [mode, setMode] = React.useState('Add');
  const navigate = useNavigate();
  const bIsAdmin = (userSession.UserType === 'Admin');
  const [initvalues, setInitValues] = useState({
    id: '',
    seqno: 0,
    ActionDate: new Date(),
    ActionType: '',
    ActionDescription: '',
    ActionStatus: ''
  });

  useEffect(() => {
    if (initvalues.id === '') {
      (async () => {
        const value = queryString.parse(search);
        const [taskid, seqno] = [value.tid, value.seqno];
        setInitValues({ ...initvalues, id: taskid });

        if (!value.mode || value.mode !== 'add') {
          setMode('Edit');
          const res = await adminGetCustTaskAction(taskid, seqno);

          if (res && res.status === 200 && res.custtaskaction) {
            const taskinfo = res.custtaskaction;

            setInitValues({
              id: taskid,
              seqno,
              ActionDate: taskinfo.actiondate,
              ActionType: taskinfo.actiontype,
              ActionDescription: taskinfo.actiondescription,
              ActionStatus: taskinfo.actionstatus,
            });
          } else if (res.status === 401) {
            alert('Sorry, your session has expired. Please log in again.');
            setUserSession({ ...userSession, isAuthenticated: false });
            sessionStorage.clear();
          }
        }
        setLoadingTaskAction(false);
      })();
    }
  }, [initvalues]);

  const UpdateCustTaskAction = async (values) => {
    try {
      const CustomerDto = {
        taskid: values.id,
        seqno: values.seqno,
        ActionDate: values.ActionDate,
        ActionType: values.ActionType,
        ActionDescription: values.ActionDescription,
        ActionStatus: values.ActionStatus,
      };

      const res = await adminUpdateCustTaskAction(CustomerDto);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleActionDateChange = (date) => {
    setInitValues({
      ...initvalues,
      ActionDate: date
    });
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  return (
    !loadingTaskAction && (
      <Page
        className={classes.root}
        title="Customer Cabinet Action"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={initvalues}
              validationSchema={
                Yup.object().shape({
                  ActionType: Yup.string().max(100),
                  ActionDescription: Yup.string().max(500),
                  ActionStatus: Yup.string().max(100)
                })
              }
              onSubmit={async (values, {
                setSubmitting,
                setFieldError
              }) => {
                const res = await UpdateCustTaskAction(values);

                if (res) {
                  if (res.status === 200) {
                    setOpenSaveDone(true);
                    // if (mode === 'Add') {
                    navigate('/app/cabinet', { replace: true });
                    // }
                  } else if (res.status === 401) {
                    alert('Sorry, your session has expired. Please log in again.');
                    setUserSession({ ...userSession, isAuthenticated: false });
                    sessionStorage.clear();
                  }
                }

                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardHeader
                      className={classes.cardtitle}
                      title={mode === 'Add' ? 'Add Customer Cabinet Action' : 'Edit Customer Cabinet Action'}
                      titleTypographyProps={{ variant: 'h2' }}
                    />
                    <Divider />
                    <CardContent>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          error={Boolean(touched.ActionDate && errors.ActionDate)}
                          helperText={touched.ActionDate && errors.ActionDate}
                          margin="normal"
                          id="ActionDate"
                          name="ActionDate"
                          label="Action Date"
                          format="DD MMM YYYY"
                          onChange={handleActionDateChange}
                          onBlur={handleBlur}
                          value={values.ActionDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <TextField
                        error={Boolean(touched.ActionType && errors.ActionType)}
                        fullWidth
                        helperText={touched.ActionType && errors.ActionType}
                        label="Action Type"
                        margin="normal"
                        name="ActionType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ActionType}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.ActionDescription && errors.ActionDescription)}
                        fullWidth
                        helperText={touched.ActionDescription && errors.ActionDescription}
                        label="Action Description"
                        margin="normal"
                        name="ActionDescription"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ActionDescription}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.ActionStatus && errors.ActionStatus)}
                        fullWidth
                        helperText={touched.ActionStatus && errors.ActionStatus}
                        label="Action Status"
                        margin="normal"
                        name="ActionStatus"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ActionStatus}
                        variant="outlined"
                      />
                      <Box my={2}>
                        <table border="0" cellPadding="2" cellSpacing="0" align="right">
                          <tr>
                            <td>
                              <div className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  onClick={() => navigate(`/app/cabinet`, { replace: true })}
                                >
                                  Back
                                </Button>
                              </div>
                            </td>
                            <td>
                              <div className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting}
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                >
                                  {isSubmitting ? 'Please wait ...' : 'Save'}
                                </Button>
                                {isSubmitting && (
                                  <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </Box>
                    </CardContent>
                  </Card>
                </form>
              )}
            </Formik>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={3000}
              open={openSaveDone}
              onClose={handleSaveDoneClose}
              message="Customer cabinet updated successfully"
              key="topcenter"
            />
          </Container>
        </Box>
      </Page>
    )
  );
};

export default TaskActionView;

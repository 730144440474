export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily: "sans-serif",
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    fontFamily: "sans-serif",
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: "sans-serif",
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "sans-serif",
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "sans-serif",
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "sans-serif",
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  },
  body1: {
    fontFamily: "sans-serif",
    fontWeight: 400,
    fontSize: 20,
  },
  footer: {
    fontFamily: "sans-serif",
    fontWeight: 100,
    fontSize: 12,
  }
};

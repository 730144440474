import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import CustomerDetailsView from 'src/views/customer/CustomerDetailsView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import SubscriptionListView from 'src/views/subscription/SubscriptionListView';
import PayBillView from 'src/views/subscription/PayBillView';
import PayEmailView from 'src/views/subscription/PayEmailView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import SetPasswordView from 'src/views/auth/SetPasswordView';
import CreateSubscriptionView from 'src/views/product/CreateSubscriptionView';
import SubscriptionDetailsView from 'src/views/subscription/SubscriptionDetailsView';
import PayLinkHandler from 'src/views/subscription/PayBillView/PayLinkHandler';
import ForgotPassword from 'src/views/auth/ForgotPassword';
import TaskListView from 'src/views/task/TaskListView';
import TaskDetailsView from 'src/views/task/TaskDetailsView';
import TaskActionView from 'src/views/task/TaskActionView';
import TaskUploadFileView from 'src/views/task/TaskUploadFileView';
import RequestListView from 'src/views/billingrequest/RequestListView';
import RequestDetailsView from 'src/views/billingrequest/RequestDetailsView';

const routes = (userSession) => [
  {
    path: 'handler',
    element: <MainLayout />,
    children: [
      { path: 'pay', element: <PayLinkHandler /> },
      { path: 'login', element: <LoginView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'app',
    element: userSession.isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers/details', element: <CustomerDetailsView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'cabinet/uploadfile', element: <TaskUploadFileView /> },
      { path: 'cabinet/actions', element: <TaskActionView /> },
      { path: 'cabinet/details', element: <TaskDetailsView /> },
      { path: 'cabinet', element: <TaskListView /> },
      { path: 'billingrequest', element: <RequestListView /> },
      { path: 'billingrequest/details', element: <RequestDetailsView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'subscriptions', element: <SubscriptionListView /> },
      { path: 'subscriptions/details', element: <SubscriptionDetailsView /> },
      { path: 'subscriptions/payform', element: <PayBillView /> },
      { path: 'subscriptions/email', element: <PayEmailView /> },
      { path: 'products/subscribe', element: <CreateSubscriptionView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: !userSession.isAuthenticated ? <MainLayout /> : <Navigate to="/app/subscriptions" />,
    children: [
      { path: 'setpassword', element: <SetPasswordView /> },
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'forgotpassword', element: <ForgotPassword /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/subscriptions" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ProductCard = ({ className, product, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardActionArea onClick={() => navigate(`/app/products/subscribe?product=${product.productcode}`, { replace: true })}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            mb={3}
          >
            <img
              alt="Product"
              src={product.media}
              variant="square"
            />
          </Box>
        </CardContent>
      </CardActionArea>
      <Box flexGrow={1} />
      <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button size="small" color="primary" onClick={() => navigate(`/app/products/subscribe?product=${product.productcode}`, { replace: true })}>
          Subscribe
        </Button>
      </CardActions>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;

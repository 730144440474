import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#2F5597',
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
}));

const SubscriptionInfo = ({
  className,
  customerinfo,
  subscription,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item sm={3} xs={6}>
              <Typography
                className={classes.label}
                variant="body1"
              >
                Subscription ID
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {subscription.header.subscriptionid}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography
                className={classes.label}
                variant="body1"
              >
                Subscription Date
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {subscription.header.subscriptiondatedisplay}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography
                className={classes.label}
                variant="body1"
              >
                Customer Name
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {customerinfo.name}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography
                className={classes.label}
                variant="body1"
              >
                Subscription Period
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {`${subscription.header.periodfromdisplay} to ${subscription.header.periodtodisplay}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardHeader
          className={classes.cardtitle}
          title="Subscription Details"
          titleTypographyProps={{ variant: 'h3' }}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            {subscription.items.map((subscribedItem) => (
              <>
                <Grid
                  item
                  md={8}
                  xs={12}
                >
                  <Paper className={classes.paper}>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {subscribedItem.itemdescription}
                    </Typography>
                  </Paper>
                </Grid>
              </>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

SubscriptionInfo.propTypes = {
  className: PropTypes.string,
  customerinfo: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired
};

export default SubscriptionInfo;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import { adminGetCustTaskList } from 'src/libs/AdminServices';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TaskListView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [custtasks, setCustTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let bIncludeDone = false;
        const value = queryString.parse(search);
        if (value.includeDone && value.includeDone === "Y") {
          bIncludeDone = true;
        }

        const res = await adminGetCustTaskList(null, bIncludeDone);
        if (res && res.status === 200) {
          setCustTasks(res.custtasks);
        } else if (res.status === 401) {
          alert('Sorry, your session has expired. Please log in again.');
          setUserSession({ ...userSession, isAuthenticated: false });
          sessionStorage.clear();
        } else {
          alert(res.error);
        }

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    !isLoading && (
      <Page
        className={classes.root}
        title="Customer Cabinet"
      >
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <form method="Post" action="https://www.xmlhk.com/tracking/TrackReadOrderForm.asp" id="frmTrackingOrder" target="_blank">
              <input type="hidden" name="id_OrderFormStatus" value="Search" />
              <input type="hidden" id="OrderFormID" name="OrderFormID" value="" />
              <input type="hidden" id="UserID" name="UserID" value="" />
            </form>
            <Results custtasks={custtasks} />
          </Box>
        </Container>
      </Page>
    )
  );
};

export default TaskListView;

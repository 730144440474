import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  Link,
  Snackbar,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import SessionContext from 'src/libs/SessionContext';
import Page from 'src/components/Page';
import { adminValidateForgotPasswordToken, adminSetPassword } from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const SetPasswordView = (props) => {
  const classes = useStyles();
  const { search } = useLocation();
  const [openSaveDone, setOpenSaveDone] = React.useState(false);
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [showUpdateDone, setShowUpdateDone] = React.useState(false);
  const [showTokenError, setShowTokenError] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initvalues, setInitValues] = useState({
    password: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const value = queryString.parse(search);

        const tokeninfo = {
          id: value.id,
          token: value.token,
          password: '',
          autologin: 'N'
        };

        const res = await adminValidateForgotPasswordToken(tokeninfo);
        if (res && res.status !== 200) {
          setShowTokenError(true);
        }
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const UpdateUserPassword = async (values) => {
    const value = queryString.parse(search);

    try {
      const passwordinfo = {
        id: value.id,
        token: value.token,
        password: values.password
      };

      const res = await adminSetPassword(passwordinfo);

      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleSaveDoneClose = () => {
    setOpenSaveDone(false);
  };

  if (showTokenError) {
    return (
      <TokenError />
    );
  }

  if (showUpdateDone) {
    return (
      <SetPasswordDone />
    );
  }

  return (
    !isLoading && (
      <Page
        className={classes.root}
        title="Set Password"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <div>
              <Formik
                enableReinitialize
                initialValues={initvalues}
                validationSchema={Yup.object().shape({
                  password: Yup.string().min(8).max(20).required('Password is required')
                    .matches(
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
                      "Must Contain 8 Characters, at least one uppercase, one lowercase, one number and one special character"
                    ),
                  confirmPassword: Yup.string().min(8).max(20).required('Confirm password is required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  const res = await UpdateUserPassword(values);
                  if (res) {
                    if (res.status === 200) {
                      setOpenSaveDone(true);
                      setShowUpdateDone(true);
                    } else if (res.status === 401) {
                      alert('Sorry, your session has expired. Please log in again.');
                      setUserSession({ ...userSession, isAuthenticated: false });
                      sessionStorage.clear();
                    } else {
                      alert(res.error);
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form
                    onSubmit={handleSubmit}
                  >
                    <Card>
                      <CardHeader
                        title="Set Password"
                        titleTypographyProps={{ variant: 'h2' }}
                      />
                      <Divider />
                      <CardContent>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          label="Password"
                          helperText={touched.password && errors.password}
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password}
                          variant="outlined"
                        />
                        <TextField
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          fullWidth
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          label="Confirm password"
                          margin="normal"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.confirmPassword}
                          variant="outlined"
                        />
                      </CardContent>
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        p={2}
                      >
                        <div className={classes.wrapper}>
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            color="primary"
                            variant="contained"
                          >
                            {isSubmitting ? 'Please wait ...' : 'Update'}
                          </Button>
                          {isSubmitting
                            && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </Box>
                    </Card>
                  </form>
                )}
              </Formik>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                open={openSaveDone}
                onClose={handleSaveDoneClose}
                message="Password updated successfully"
                key="topcenter"
              />
            </div>
          </Container>
        </Box>
      </Page>
    )
  );
};

const SetPasswordDone = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Typography
            color="textPrimary"
            variant="h6"
          >
            The password has been updated successfully.
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            align="right"
          >
            <Link
              component={RouterLink}
              to="/login"
              variant="h6"
            >
              Back
            </Link>
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

const TokenError = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Typography
            color="textPrimary"
            variant="h6"
          >
            The token has been expired or invalid.
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            align="right"
          >
            <Link
              component={RouterLink}
              to="/login"
              variant="h6"
            >
              Back
            </Link>
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default SetPasswordView;

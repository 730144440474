import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Link,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import SessionContext from 'src/libs/SessionContext';
import { adminValidateUser, adminLogout } from 'src/libs/AdminServices';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

const LoginForm = (props) => {
  const {
    onLoginOK,
    userEmail,
    ...other
  } = props;
  const classes = useStyles();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const navigate = useNavigate();
  const [initvalues, setInitvalues] = React.useState({ email: userSession.UserEmail, password: '' });

  const ValidateUser = async (values) => {
    try {
      const res = await adminValidateUser(values.email, values.password);
      return res;
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const LoginOk = () => {
    setInitvalues({ email: userSession.UserEmail, password: '' });
    onLoginOK();
  };

  const HandleLogout = async (event) => {
    event.preventDefault();
    try {
      const _userSession = {
        isAuthenticated: false,
        isAuthenticating: false,
        showTimeOutLogin: false,
        UserID: '',
        UserName: '',
        UserType: '',
        UserEmail: '',
        Avatar: '',
        token: ''
      };

      const res = await adminLogout();
      setUserSession(_userSession);
      sessionStorage.clear();

      navigate('/login', { replace: true });
    } catch (e) {
      alert('error');
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initvalues}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(50).required('Email is required'),
        password: Yup.string().max(100).required('Password is required')
      })}
      onSubmit={async (values, {
        setSubmitting,
        setFieldError,
        setFieldValue
      }) => {
        const res = await ValidateUser(values);

        if (res) {
          if (res.status === 200) {
            const _userSession = {
              isAuthenticated: true,
              isAuthenticating: false,
              showTimeOutLogin: false,
              UserID: res.userid,
              UserName: res.username,
              UserType: res.usertype,
              UserEmail: res.email,
              Avatar: res.avatar,
              CustomerID: res.customerid,
              Market: res.market
            };

            setUserSession(_userSession);
            sessionStorage.setItem('isAuthenticated', 'true');
            setFieldValue('password', '');
            LoginOk();
          } else {
            setFieldError('password', res.error);
          }
        }
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            disabled={userSession.UserEmail}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKey />
                </InputAdornment>
              ),
            }}
          />
          <Box my={2}>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {isSubmitting ? 'Please wait ...' : 'Sign in now' }
              </Button>
              {isSubmitting
                && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            {userSession.UserEmail
              && (
                <>
                  <div>
                    <Typography
                      color="textSecondary"
                      align="center"
                      variant="body1"
                    >
                      <Link
                        component="button"
                        variant="h6"
                        onClick={HandleLogout}
                      >
                        Sign in as another user
                      </Link>
                    </Typography>
                  </div>
                </>
              )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onLoginOK: PropTypes.func.isRequired,
};

export default LoginForm;

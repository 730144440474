import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Tooltip,
  Typography,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SessionContext from 'src/libs/SessionContext';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const navigate = useNavigate();
  const { userSession, setUserSession } = React.useContext(SessionContext);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.billingdatedisplay}
        </TableCell>
        <TableCell>{row.duedatedisplay}</TableCell>
        <TableCell>{row.paymentmethod}</TableCell>
        <TableCell align="center">
          <Chip
            color="primary"
            label={row.paymentstatusname}
            size="small"
          />
        </TableCell>
        <TableCell align="right">{row.totalpricedisplay}</TableCell>
        <TableCell align="right">{row.outstandingamountdisplay}</TableCell>
        <TableCell align="center">
          {row.allowpay && userSession.UserType !== 'Admin' && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={() => navigate(`/app/subscriptions/payform?sid=${row.subscriptionid}&bid=${row.billingid}`, { replace: true })}
            >
              Pay now
            </Button>
          )}
          {row.allowpay && userSession.UserType === 'Admin' && (
            <Tooltip disableFocusListener disableTouchListener title="Email payment link to customer">
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={() => navigate(`/app/subscriptions/email?sid=${row.subscriptionid}&bid=${row.billingid}`, { replace: true })}
              >
                Email
              </Button>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="chargeDescription">
                <TableHead>
                  <TableRow>
                    <TableCell>Charge Description</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Outstanding</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((itemRow) => (
                    <TableRow key={itemRow.itemid}>
                      <TableCell component="th" scope="row">
                        {itemRow.itemdescription}
                      </TableCell>
                      <TableCell align="right">{itemRow.pricedisplay}</TableCell>
                      <TableCell align="right">{itemRow.outstandingamountdisplay}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {row.paymenthistory.length > 0 && (
                <>
                  <br />
                  <Table size="small" aria-label="PaymentHistory">
                    <TableHead>
                      <TableRow>
                        <TableCell>Payment History</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Paid Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.paymenthistory.map((historyRow) => (
                        <TableRow key={historyRow.paymentseqno}>
                          <TableCell component="th" scope="row">
                            {historyRow.paymentref}
                          </TableCell>
                          <TableCell align="right">{historyRow.paymentdatedisplay}</TableCell>
                          <TableCell align="right">{historyRow.paymentstatusname}</TableCell>
                          <TableCell align="right">{historyRow.paidamountdisplay}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    subscriptionid: PropTypes.number.isRequired,
    billingid: PropTypes.number.isRequired,
    billingdatedisplay: PropTypes.string.isRequired,
    duedatedisplay: PropTypes.string.isRequired,
    paymentmethod: PropTypes.string.isRequired,
    paymentstatus: PropTypes.string.isRequired,
    totalpricedisplay: PropTypes.string.isRequired,
    outstandingamountdisplay: PropTypes.string.isRequired,
    allowpay: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        itemid: PropTypes.number.isRequired,
        itemdescription: PropTypes.string.isRequired,
        pricedisplay: PropTypes.string.isRequired,
        outstandingamountdisplay: PropTypes.string.isRequired,
      }),
    ).isRequired,
    paymenthistory: PropTypes.arrayOf(
      PropTypes.shape({
        paymentseqno: PropTypes.number.isRequired,
        paymentref: PropTypes.string.isRequired,
        paymentdatedisplay: PropTypes.string.isRequired,
        paymentstatusname: PropTypes.string.isRequired,
        paidamountdisplay: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

const BillingList = ({ className, billings, ...rest }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Billing Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell align="center">Payment Status</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Outstanding</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billings.map((row) => (
            <Row key={row.billingid} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BillingList.propTypes = {
  className: PropTypes.string,
  billings: PropTypes.array.isRequired
};

export default BillingList;

import React, { useState } from 'react';
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tooltip,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import SessionContext from 'src/libs/SessionContext';

const useRowStyles = makeStyles({
  shortdesc: {
    borderTop: '0px'
  },
  tabletitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
});

function Row(props) {
  const { row } = props;
  const classes = useRowStyles();
  const navigate = useNavigate();

  const copyRequest = async (rid, e) => {
    e.preventDefault();

    navigate(`/app/billingrequest/details?mode=copy&rid=${rid}`, { replace: true });

    return null;
  };

  return (
    <>
      <TableRow>
        <TableCell><b>{row.requestid}</b></TableCell>
        <TableCell><b>{row.requeststatusname}</b></TableCell>
        <TableCell component="th" scope="row">
          <b>{row.requestdatedisplay}</b>
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.billingdatedisplay}</b>
        </TableCell>
        <TableCell><b>{row.quotno}</b></TableCell>
        <TableCell>
          <b>{row.custname}</b>
        </TableCell>
        <TableCell align="center">
          <Tooltip disableFocusListener disableTouchListener title="Copy">
            <IconButton aria-label="row" size="small" onClick={(e) => copyRequest(row.requestid, e)}>
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip disableFocusListener disableTouchListener title="Edit">
            <IconButton aria-label="row" size="small" onClick={() => navigate(`/app/billingrequest/details?rid=${row.requestid}`, { replace: true })}>
              <Edit />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {row.shortprojectdescription !== "" && (
        <TableRow>
          <TableCell colSpan={7} className={classes.shortdesc}>
            <b>
              {row.shortprojectdescription}
            </b>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    requestid: PropTypes.string.isRequired,
    requeststatusname: PropTypes.string.isRequired,
    requestby: PropTypes.string.isRequired,
    requestdatedisplay: PropTypes.string.isRequired,
    billingdatedisplay: PropTypes.string.isRequired,
    companyname: PropTypes.string.isRequired,
    quotno: PropTypes.string.isRequired,
    custname: PropTypes.string.isRequired,
    shortprojectdescription: PropTypes.string.isRequired,
  }).isRequired,
};

const Results = ({ className, billingreqs, ...rest }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Request No.</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Request Date</TableCell>
            <TableCell>Billing Date</TableCell>
            <TableCell>Quotation No.</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billingreqs.map((row) => (
            <Row key={row.taskid} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;

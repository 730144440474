import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import { adminGetSubscriptionList } from 'src/libs/AdminServices';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SubscriptionListView = () => {
  const classes = useStyles();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [subscriptions, setSubscriptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await adminGetSubscriptionList(null);
        if (res && res.status === 200) {
          setSubscriptions(res.subscriptions);
        } else if (res.status === 401) {
          alert('Sorry, your session has expired. Please log in again.');
          setUserSession({ ...userSession, isAuthenticated: false });
          sessionStorage.clear();
        } else {
          alert(res.error);
        }

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    !isLoading && (
      <Page
        className={classes.root}
        title="Subscriptions"
      >
        <Container maxWidth={false}>
          <Box mt={3}>
            <Results subscriptions={subscriptions} />
          </Box>
        </Container>
      </Page>
    )
  );
};

export default SubscriptionListView;

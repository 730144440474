import React, {
  useState,
  useEffect,
  forwardRef,
  createRef
} from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Paper,
  Button,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Autocomplete, Pagination } from '@material-ui/lab';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MaterialTable from "material-table";
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import {
  adminGetCustomerInfoByUser,
  adminGetPricingInfo,
  adminCreateSubscription
} from 'src/libs/AdminServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: grey[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
  cardtitle2: {
    color: '#FFFFFF',
    backgroundColor: '#FF6E6E'
  },
  cardbody: {
    backgroundColor: '#A6D1ED'
  },
  cardbody2: {
    backgroundColor: '#FF9D9D'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    backgroundColor: '#DAE0E5'
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const CreateSubscriptionView = () => {
  const classes = useStyles();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const [orderHeader, setOrderHeader] = useState({
    subscribedby: userSession.UserID,
    customerid: -1,
    customer: {},
    product: '',
    nooflicense: 0,
    periodfrom: new Date(),
    periodto: new Date()
  });
  const [orderSummary, setOrderSummary] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [pricingInfo, setPricingInfo] = useState({});
  const [openCustomer, setOpenCustomer] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const loading = openCustomer && customers.length === 0;
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState({
    success: false,
    title: 'Error',
    message: ''
  });

  const itemOptions = [
    { title: 'One-off implementation' },
    { title: 'Add-on' },
  ];

  const [columns, setColumns] = useState([
    {
      title: 'Description',
      field: 'itemdescription',
      cellStyle: {
        width: 400,
        maxWidth: 400,
      },
      headerStyle: {
        width: 400,
        maxWidth: 400,
      },
      /*
      editComponent: (props) => (
        <Autocomplete
         id="itemname"
          freeSolo
          options={itemOptions}
          onChange={(e) => props.onChange(e.target.value)}
          getOptionLabel={(option) => option.title}
          value={(v) => props.value}
          renderInput={
            (params) => (
              <TextField
                {...params}
                label="Item"
                margin="normal"
                variant="outlined"
                value={props.value}
              />
            )
          }
        />
      )
      */
    },
    {
      title: 'Charge Type',
      field: 'itemtype',
      lookup: {
        oneoff: 'One off',
        monthly: 'Monthly'
      },
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      }
    },
    {
      title: 'Currency',
      field: 'currency',
      editable: 'never',
      cellStyle: {
        width: 50,
        maxWidth: 50,
      },
      headerStyle: {
        width: 50,
        maxWidth: 50,
      }
    },
    { title: 'Price', field: 'price', type: 'numeric' },
  ]);

  const handleFromDateChange = (date) => {
    setOrderHeader({
      ...orderHeader,
      periodfrom: date
    });
  };

  const handleToDateChange = (date) => {
    setOrderHeader({
      ...orderHeader,
      periodto: date
    });
  };

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await adminGetCustomerInfoByUser();
      if (active) {
        setCustomers(res.customers);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!openCustomer) {
      setCustomers([]);
    }
  }, [openCustomer]);

  const RefreshOrderSummary = (value) => {
    const newOrderSummary = [];

    // Add One Off
    if (pricingInfo.oneOffPrice && pricingInfo.oneOffPrice.length > 0) {
      const qstr = queryString.parse(search);

      pricingInfo.oneOffPrice.forEach((priceInfo, index) => {
        if (value >= priceInfo.qtyFrom
            && value <= priceInfo.qtyTo
            && qstr.product === priceInfo.product) {
          newOrderSummary.push({
            itemtype: 'oneoff',
            itemdescription: 'One-off Implementation Charge',
            currency: priceInfo.currencyCode,
            price: priceInfo.price,
            pricedisplay: (priceInfo.price).toLocaleString(
              navigator.language, { minimumFractionDigits: 0 }
            )
          });
        }
      });
    }

    setOrderSummary(newOrderSummary);
    if (newOrderSummary.length > 0) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  };

  const fetchPricingData = async (_product, _market) => {
    const res = await adminGetPricingInfo(_product, _market);

    if (res && res.status === 200) {
      setPricingInfo(res);
    }
  };

  // When order, pricing info changed, refresh order summary
  React.useEffect(() => {
    RefreshOrderSummary(orderHeader.nooflicense);
  }, [pricingInfo]);

  React.useEffect(() => {
    const qstr = queryString.parse(search);
    let datefrom = new Date().setHours(0, 0, 0, 0);
    datefrom = new Date(datefrom);
    const year = new Date(datefrom).getFullYear();
    const month = new Date(datefrom).getMonth();
    const day = new Date(datefrom).getDate();
    let dateto = new Date(year + 1, month, day)
      .setDate(new Date(year + 1, month, day).getDate() - 1);
    dateto = new Date(dateto);

    if (userSession.UserType === 'Admin') {
      setOrderHeader({
        ...orderHeader,
        customerid: -1,
        periodfrom: datefrom,
        periodto: dateto,
        product: qstr.product
      });
    } else {
      setOrderHeader({
        ...orderHeader,
        customerid: userSession.CustomerID,
        periodfrom: datefrom,
        periodto: dateto,
        product: qstr.product
      });
      fetchPricingData(qstr.product, userSession.Market);
    }
  }, []);

  const CreateSubscription = async (values) => {
    try {
      if (!orderSummary || orderSummary.length === 0) {
        alert("Please input the item first");
      } else {
        const res = await adminCreateSubscription(orderHeader, orderSummary);
        return res;
      }
    } catch (e) {
      alert('error');
    }

    return null;
  };

  const handleErrorDialogOpen = () => {
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
  };

  const tableRef = createRef();

  return (
    <Page
      className={classes.root}
      title="Subscription"
    >
      <Container maxWidth={false}>
        <Formik
          enableReinitialize
          initialValues={{
            nooflicense: orderHeader.nooflicense,
            periodfrom: orderHeader.periodfrom,
            periodto: orderHeader.periodto,
            customer: orderHeader.customer
          }}
          validationSchema={
            Yup.object().shape({
              nooflicense: Yup.number().min(0).max(20).required('No. of license is required')
            })
          }
          onSubmit={async (values, {
            setSubmitting,
            setFieldError
          }) => {
            if (orderHeader.periodto <= orderHeader.periodfrom) {
              setFieldError('periodto', 'To date should be later than from date');
            } else if (tableRef.current.state.lastEditingRow || tableRef.current.state.showAddRow) {
              alert('Please save the item first');
            } else {
              const res = await CreateSubscription(values);

              if (res) {
                if (res.status === 200) {
                  if (res.firstpaybillingid) {
                    if (userSession.UserType === 'Admin') {
                      navigate(`/app/subscriptions/email?sid=${res.subscriptionid}&bid=${res.firstpaybillingid}`, { replace: true });
                    } else {
                      navigate(`/app/subscriptions/payform?sid=${res.subscriptionid}&bid=${res.firstpaybillingid}`, { replace: true });
                    }
                  } else {
                    navigate(`/app/subscriptions/details?id=${res.subscriptionid}`, { replace: true });
                  }
                } else if (res.status === 401) {
                  alert('Sorry, your session has expired. Please log in again.');
                  setUserSession({ ...userSession, isAuthenticated: false });
                  sessionStorage.clear();
                } else {
                  setErrorMessage({
                    success: false,
                    title: 'Error',
                    message: res.error
                  });
                  handleErrorDialogOpen(true);
                }
              }
            }
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <>
                <Card>
                  <CardContent>
                    {userSession.UserType === 'Admin' && (
                      <Autocomplete
                        id="customerid"
                        name="customerid"
                        open={openCustomer}
                        onOpen={() => {
                          setOpenCustomer(true);
                        }}
                        onClose={() => {
                          setOpenCustomer(false);
                        }}
                        options={customers}
                        loading={loading}
                        getOptionLabel={(option) => option.name}
                        value={values.customer}
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          setFieldValue('customer', value);
                          const qstr = queryString.parse(search);

                          setOrderHeader({
                            ...orderHeader,
                            customerid: value ? value.id : -1,
                            customer: value
                          });
                          if (value) {
                            fetchPricingData(qstr.product, value.market);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="customer"
                            label="Customer"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            error={Boolean(touched.customer && errors.customer)}
                            helperText={touched.customer && errors.customer}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                    {orderHeader.customerid > -1 && (
                      <>
                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            md={3}
                            xs={12}
                          >
                            <Typography variant="h3" component="h2">
                              Subscription Period
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={9}
                            xs={12}
                          >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <Grid container justify="space-around">
                                <KeyboardDatePicker
                                  error={Boolean(touched.periodfrom && errors.periodfrom)}
                                  helperText={touched.periodfrom && errors.periodfrom}
                                  margin="normal"
                                  id="periodfrom"
                                  name="periodfrom"
                                  label="From"
                                  format="DD MMM YYYY"
                                  onChange={handleFromDateChange}
                                  onBlur={handleBlur}
                                  value={values.periodfrom}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                                <KeyboardDatePicker
                                  error={Boolean(touched.periodto && errors.periodto)}
                                  helperText={touched.periodto && errors.periodto}
                                  margin="normal"
                                  id="periodto"
                                  name="periodto"
                                  label="To"
                                  format="DD MMM YYYY"
                                  onChange={handleToDateChange}
                                  onBlur={handleBlur}
                                  value={values.periodto}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            xs={12}
                          >
                            <Typography variant="h3" component="h2">
                              No. of User License
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={9}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nooflicense && errors.nooflicense)}
                              name="nooflicense"
                              variant="outlined"
                              type="number"
                              style={{ width: 80, height: 20 }}
                              onBlur={handleBlur}
                              onChange={(event) => {
                                const { value } = event.target;
                                setFieldValue(
                                  'nooflicense',
                                  value
                                );
                                if (orderHeader.nooflicense === 0 || orderHeader.nooflicense === '0'
                                  || value === 0 || value === '0') {
                                  RefreshOrderSummary(value);
                                }
                                setOrderHeader({
                                  ...orderHeader,
                                  nooflicense: value,
                                });
                              }}
                              value={values.nooflicense}
                              InputProps={{
                                inputProps: {
                                  max: 20, min: 0
                                }
                              }}
                            />
                            <ErrorMessage name="nooflicense">
                              { (msg) => (
                                <span style={{ color: 'red' }}>
                                  &nbsp;
                                  {msg}
                                </span>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                        <br />
                      </>
                    )}
                  </CardContent>
                </Card>
              </>
              {orderHeader.customerid > -1 && showSummary && (
              <>
                <br />
                <Card>
                  <CardHeader
                    className={classes.cardtitle}
                    title="Subscription Details"
                    titleTypographyProps={{ variant: 'h3' }}
                  />
                  <Divider />
                  <CardContent>
                    <MaterialTable
                      tableRef={tableRef}
                      icons={tableIcons}
                      title=""
                      columns={columns}
                      options={{
                        search: false,
                        sorting: false,
                        paging: false,
                        draggable: false
                      }}
                      data={orderSummary}
                      editable={{
                        onRowAdd: (newData) => new Promise((resolve, reject) => {
                          setTimeout(() => {
                            if (!newData.itemdescription) {
                              alert('Description is required');
                              reject();
                              return;
                            }

                            if (!newData.itemtype) {
                              alert('Charge type is required');
                              reject();
                              return;
                            }

                            if (!newData.currency) {
                              if (pricingInfo.oneOffPrice && pricingInfo.oneOffPrice.length > 0) {
                                newData.currency = pricingInfo.oneOffPrice[0].currencyCode;
                              } else {
                                alert('Currency is required');
                                reject();
                                return;
                              }
                            }

                            if (!newData.price) {
                              alert('Price is required');
                              reject();
                              return;
                            }

                            setOrderSummary([...orderSummary, newData]);
                            resolve();
                          }, 1000);
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                          setTimeout(() => {
                            const dataUpdate = [...orderSummary];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setOrderSummary([...dataUpdate]);
                            resolve();
                          }, 1000);
                        }),
                        onRowDelete: (oldData) => new Promise((resolve, reject) => {
                          setTimeout(() => {
                            const dataDelete = [...orderSummary];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setOrderSummary([...dataDelete]);
                            resolve();
                          }, 1000);
                        }),
                      }}
                    />
                  </CardContent>
                </Card>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <div className={classes.wrapper}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                    >
                      {isSubmitting ? 'Please wait ...' : 'Subscribe'}
                    </Button>
                    {isSubmitting
                      && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </Box>
                <Dialog
                  fullWidth
                  maxWidth="sm"
                  open={openErrorDialog}
                  onClose={handleErrorDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <Typography variant="h3">
                      {errorMessage.title}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {errorMessage.message}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleErrorDialogClose} color="primary" autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
              )}
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default CreateSubscriptionView;

import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  footer: {
    background: "#292F45",
    textAlign: "center",
    verticalAlign: "center",
    height: "50px",
    color: "#FFFFFF",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.footer}>
      <Typography variant="footer" color="inherit">
        © 2022 by Excellent Management Limited
      </Typography>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SessionContext from 'src/libs/SessionContext';
import { adminGetSubscriptionDetail } from 'src/libs/AdminServices';
import BillingList from './BillingList';
import SubscriptionInfo from './SubscriptionInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardtitle: {
    color: '#FFFFFF',
    backgroundColor: '#5A90BA'
  },
}));

const SubscriptionDetailsView = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const { userSession, setUserSession } = React.useContext(SessionContext);
  const [loadingDetail, setLoadingDetail] = React.useState(true);
  const [subscriptionDetail, setSubscriptionDetail] = React.useState({});

  React.useEffect(() => {
    async function fetchData() {
      const value = queryString.parse(search);

      try {
        const res = await adminGetSubscriptionDetail(value.id, null);
        if (res && res.status === 200) {
          setSubscriptionDetail(res);
        } else if (res.status === 401) {
          alert('Sorry, your session has expired. Please log in again.');
          setUserSession({ ...userSession, isAuthenticated: false });
          sessionStorage.clear();
        } else {
          alert(res.error);
        }
        setLoadingDetail(false);
      } catch (e) {
        console.log(e);
        setLoadingDetail(false);
      }
    }
    fetchData();
  }, []);

  return (
    !loadingDetail && subscriptionDetail.status === 200 && (
      <Page
        className={classes.root}
        title="Subscription details"
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <Container maxWidth="lg">
            <>
              <SubscriptionInfo
                customerinfo={subscriptionDetail.customerinfo}
                subscription={subscriptionDetail.subscription}
              />
              <br />
              <Card>
                <CardHeader
                  className={classes.cardtitle}
                  title="Billing Details"
                  titleTypographyProps={{ variant: 'h3' }}
                />
                <Divider />
                <CardContent>
                  <BillingList billings={subscriptionDetail.subscription.billings} />
                </CardContent>
              </Card>
            </>
          </Container>
        </Box>
      </Page>
    )
  );
};

export default SubscriptionDetailsView;
